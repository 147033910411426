import React, { useState, useEffect, useContext, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { RootState, useAppSelector, useAppDispatch } from 'src/store';
import { Button, Flex, Modal, Text } from 'src/components/common';
import { DateTimePicker } from 'src/components/shared';
import { IsMobileContext } from 'src/context/IsMobileContext';
import { parseError } from 'src/utils/error-parser';
import { ToastNotifications } from 'src/utils/toast-notifications';
import { appointmentsAPI } from 'src/api/appointments/ApiRequests';
import { RoutesEnum } from 'src/routes';
import { config } from 'src/config/config';
import { RolesEnum } from 'src/constants/RolesEnum';
import { setSelectedSession } from 'src/store/slices/sessionSlice';
import { useTranslation } from 'react-i18next';
import { User } from 'src/types/User';
import { format } from 'date-fns';

interface Props {
  therapist: User;
  clientId: number;
  isOpen: boolean;
  redirectRoute: RoutesEnum;
  buttonLabel: string;
  onClose: () => void;
}

export const BookTherapistModal = ({ isOpen, onClose, therapist, clientId, redirectRoute, buttonLabel }: Props) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const { t } = useTranslation();
  const { isMobile } = useContext(IsMobileContext);

  const { user } = useAppSelector((state: RootState) => state.auth);
  const [loading, setLoading] = useState(false);
  const [selectedDate, setSelectedDate] = useState(new Date());

  const minTimeBeforeBooking = useMemo(() => {
    // For clients booking appointment should be available only 48hrs before session
    let minTimeBeforeBooking = config.MIN_TIME_BEFORE_BOOKING_48;
    // Therapists are able to book appointment without any time related rules
    if (user?.role === RolesEnum.THERAPIST) {
      minTimeBeforeBooking = 0;
    } else if (therapist?.earlyBooking == true) {
      // If user is booking then it depends on therapist settings
      minTimeBeforeBooking = config.MIN_TIME_BEFORE_BOOKING_24;
    }
    return new Date().getTime() + minTimeBeforeBooking;
  }, [user, therapist]);

  const availableDates = useMemo(() => {
    return (
      therapist?.availabilities
        ?.map((a) => new Date(a.date))
        ?.filter((date) => date.getTime() > minTimeBeforeBooking)
        ?.sort((a, b) => b.getDate() - a.getDate()) ?? []
    );
  }, [therapist]);

  const availableTimes = useMemo(() => {
    return availableDates
      .filter((date) => format(selectedDate, 'yyyy/MM/dd') === format(date, 'yyyy/MM/dd'))
      .filter((date) => date.getTime() > minTimeBeforeBooking);
  }, [selectedDate]);

  useEffect(() => {
    if (availableDates && availableDates.length > 0) {
      const latest = availableDates[availableDates.length - 1];
      const time = availableDates
        .filter((date) => format(latest, 'yyyy/MM/dd') === format(date, 'yyyy/MM/dd'))
        .filter((date) => date.getTime() > minTimeBeforeBooking);
      setSelectedDate(time ? time[0] : null);
    }
  }, [therapist]);

  const selectedAvailability = useMemo(() => {
    return therapist?.availabilities?.find((item) => format(selectedDate, 'yyyy/MM/dd HH:mm') === format(item.date, 'yyyy/MM/dd HH:mm'));
  }, [selectedDate, therapist]);

  async function bookUnpaidAppointment() {
    setLoading(true);

    try {
      const response = await appointmentsAPI.bookUnpaidAppointmentTherapist({
        therapistId: therapist?.id,
        clientId,
        availabilityId: selectedAvailability.id,
      });
      if (response.success) {
        await dispatch(setSelectedSession(response.data));
        ToastNotifications.success(t('msg_session_booked'), 'createAppointment');
        paymentSuccessful();
      }
    } catch (error) {
      ToastNotifications.error(parseError(error), 'createAppointment');
    } finally {
      setLoading(false);
    }
  }

  const paymentSuccessful = async () => {
    onClose();

    if (redirectRoute != null) {
      navigate(redirectRoute);
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="large">
      <Flex flexDirection={'column'} width={'100%'} alignItems={'center'} gap={'10px'}>
        <Text variant="heading5" text={t('book_therapist_1')} margin={'0 0 20px 0'} width={'100%'} textAlign={'center'} />

        <DateTimePicker
          selectedDate={selectedDate}
          setSelectedDate={setSelectedDate}
          includeDates={availableDates}
          highlightDates={availableDates}
          includeTimes={availableTimes}
          timeIntervalInMinutes={config.CALENDAR_TIME_INTERVAL_IN_MINUTES}
          minTime={config.CALENDAR_MIN_TIME}
          maxTime={config.CALENDAR_MAX_TIME}
          maxYears={config.CALENDAR_MAX_YEARS}
          excludeOutOfBoundsTimes={true}
          dayWrapperSize={isMobile ? '2rem' : '5rem'}
          dayTextSize={18}
          monthWidth={'30%'}
          timeTrackWidth={isMobile ? '15%' : '20%'}
          monthTrackWidth={isMobile ? '85%' : '80%'}
          monthYearHeaderMargin={isMobile ? 0 : 10}
        />

        <Button
          width="50%"
          type="button"
          variant="primaryLarge"
          margin={'24px'}
          disabled={loading || !selectedDate}
          isLoading={loading}
          text={buttonLabel}
          onClick={bookUnpaidAppointment}
        />
      </Flex>
    </Modal>
  );
};

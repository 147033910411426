import React, { useContext } from 'react';
import { RootState, useAppSelector, useAppDispatch } from 'src/store';
import { IsMobileContext } from 'src/context/IsMobileContext';
import { useTheme } from 'styled-components';
import { Card, Button, Flex, Text } from 'src/components/common';
import { Inbox } from 'src/types/Inbox';
import { useDisclosure } from 'src/hooks/useDisclosure';
import { MessagesModal } from 'src/components/shared';
import { setSelectedInbox } from 'src/store/slices/userSlice';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

export const AllMessages = () => {
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const { isMobile } = useContext(IsMobileContext);
  const { userInbox } = useAppSelector((state: RootState) => state.user); // TODO get unread messages from user inbox

  const { isOpen: isMessagesModalOpen, close: closeMessagesModal, open: openMessagesModal } = useDisclosure(false);

  const inboxClicked = (inbox: Inbox) => {
    dispatch(setSelectedInbox(inbox));
    openMessagesModal();
  };

  return (
    <Flex flexDirection="column" width={'100%'} gap={'16px'} overflow={'scroll'}>
      <Text variant={'heading6'} text={t('messages_1')} />

      <Card width={'100%'} borderRadius={'20px'} margin={isMobile ? '0 0 24px 0' : '0 0 48px 0'} padding={'24px'}>
        <Flex flexDirection="column" width={'100%'} height={'75vh'} gap={'32px'} overflow={'scroll'}>
          {!userInbox ||
            (userInbox.length === 0 && (
              <Flex width={'100%'} height={'100%'} flexDirection={'column'} gap="20px" justifyContent={'center'} alignItems={'center'}>
                <Text variant={'heading6'} text={t('messages_2')} />
              </Flex>
            ))}

          {userInbox?.map((inbox, index) => (
            <Flex key={index} flexDirection="column" width={'100%'} gap="10px">
              <Flex flexDirection="row" width={'100%'} alignItems={'center'} gap="10px">
                <Flex flexDirection="column" width={'100%'} gap={'10px'}>
                  <Text variant={'heading7'} text={inbox.user.username} />
                  <Text variant={'paragraph1'} text={inbox.lastMessage} />
                  <Text variant={'paragraph4'} text={moment(inbox.createdAt).format('D MMMM yyyy, HH:mm')} />
                </Flex>
                <Button variant={'primaryDefault'} text={t('btn_see_messages')} minWidth="120px" onClick={() => inboxClicked(inbox)} />
              </Flex>
              <Flex width={'100%'} height={'0px'} border={`0.05px solid ${theme.colors.border.darker}`} />
            </Flex>
          ))}
        </Flex>
      </Card>

      {setSelectedInbox && <MessagesModal isOpen={isMessagesModalOpen} onClose={closeMessagesModal} />}
    </Flex>
  );
};

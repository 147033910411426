import React, { useContext } from 'react';
import { RootState, useAppSelector, useAppDispatch } from 'src/store';
import { Button, Card, Flex, Grid, Text } from 'src/components/common';
import { IsMobileContext } from 'src/context/IsMobileContext';
import { useTheme } from 'styled-components';
import { useTranslation } from 'react-i18next';
import { setSelectedPackage } from 'src/store/slices/packagesSlice';
import { Package } from 'src/types/Package';
import { User } from 'src/types/User';
import { getPackagePrice, getPackagePriceDiscounted, getPackagePriceDiscountedWithVoucher } from 'src/utils/price-calculator';
import { CompanyVoucher } from 'src/types/CompanyVoucher';

export interface BookSessionCardStepProps {
  loading: boolean;
  therapist: User;
  packages: Package[];
  selectedPackage?: Package;
  voucher: CompanyVoucher;
  backClicked: () => void;
  nextClicked: () => void;
}

export const BookSessionPackagesStep = ({
  loading,
  therapist,
  packages,
  selectedPackage,
  voucher,
  backClicked,
  nextClicked,
}: BookSessionCardStepProps) => {
  const dispatch = useAppDispatch();
  const theme = useTheme();

  const { t } = useTranslation();
  const { isMobile } = useContext(IsMobileContext);
  const { user } = useAppSelector((state: RootState) => state.auth);

  return (
    <Card width={'100%'} height={isMobile ? 'auto' : '75vh'} borderRadius={'20px'} padding={isMobile ? '16px' : '24px'}>
      <Flex
        width={'100%'}
        height={'100%'}
        flexDirection={'column'}
        gap={'32px'}
        justifyContent={'space-between'}
        alignItems={'center'}
        overflow={'scroll'}
      >
        <Flex />

        <Grid gridTemplateColumns={isMobile ? '1rf' : `repeat(${packages.length}, 1fr)`} gap={'4px'}>
          {packages.map((item, index) => (
            <Flex
              key={index}
              flexDirection={'column'}
              border={`2.5px solid ${selectedPackage == item ? theme.colors.palette.green : theme.colors.border.lighter}`}
              borderRadius={'20px'}
              onClick={() => dispatch(setSelectedPackage(item))}
            >
              <Card width={'100%'} height={'100%'} padding={'0px'}>
                <Text
                  width={'100%'}
                  variant={'heading7'}
                  text={item.name}
                  textAlign={'center'}
                  padding={'24px'}
                  style={{
                    minHeight: '80px',
                    backgroundColor: `${theme.colors.palette.lightPurple}`,
                    borderTopLeftRadius: '18px',
                    borderTopRightRadius: '18px',
                    overflow: 'hidden',
                  }}
                />
                <Flex
                  width={'100%'}
                  height={'100%'}
                  flexDirection={'column'}
                  gap={'24px'}
                  padding={'16px'}
                  alignItems={'center'}
                  justifyContent={'space-between'}
                >
                  <Flex width={'100%'} flexDirection={'column'} gap={'24px'} justifyContent={'center'}>
                    <Flex width={'100%'} flexDirection={'column'} justifyContent={'center'} alignItems={'center'}>
                      <Text
                        variant={'heading5'}
                        width={'100%'}
                        text={`${getPackagePrice(user, item, therapist?.price)}`}
                        textAlign={'center'}
                        textDecoration={item.discount > 0 ? 'line-through' : 'none'}
                      />
                      {item.sessions == 1 && voucher != null && (
                        <Card borderRadius={'12px'} backgroundColor={theme.colors.palette.red} padding={'8px 16px'} margin={'8px'}>
                          <Text
                            variant={'heading5'}
                            text={`Uz vaučer ${getPackagePriceDiscountedWithVoucher(user, item, therapist?.price, voucher)}`}
                            textAlign={'center'}
                            color={theme.colors.palette.white}
                          />
                        </Card>
                      )}
                      {item.discount > 0 && (
                        <Card borderRadius={'12px'} backgroundColor={theme.colors.palette.red} padding={'8px 16px'} margin={'8px'}>
                          <Text
                            variant={'heading5'}
                            text={getPackagePriceDiscounted(user, item, therapist?.price)}
                            textAlign={'center'}
                            color={theme.colors.palette.white}
                          />
                        </Card>
                      )}
                      <Text variant={'heading5'} width={'100%'} text={`${item.sessions > 1 ? '/ mjesečno' : ''}`} textAlign={'center'} />
                    </Flex>
                  </Flex>
                  <Text variant={'paragraph1'} width={'100%'} text={item.description} textAlign={'center'} />

                  <Flex width={'100%'} flexDirection={'column'} gap={'16px'}>
                    {item.sessions > 1 && (
                      <Flex width={'100%'} flexDirection={'row'} gap={'8px'} margin={'24px 0 0 0 '}>
                        <Flex
                          style={{
                            minWidth: '20px',
                            minHeight: '20px',
                            borderRadius: '50%',
                            backgroundColor: theme.colors.palette.green,
                          }}
                        />
                        <Text variant={'paragraph1'} text={`${item.sessions} online sesije mjesečno`} />
                      </Flex>
                    )}
                    <Flex width={'100%'} flexDirection={'row'} gap={'8px'}>
                      <Flex
                        style={{
                          minWidth: '20px',
                          minHeight: '20px',
                          borderRadius: '50%',
                          backgroundColor: theme.colors.palette.green,
                        }}
                      />
                      <Text variant={'paragraph1'} text={t('therapist_subscription_7')} />
                    </Flex>
                    <Flex />
                  </Flex>
                </Flex>
              </Card>
            </Flex>
          ))}
        </Grid>

        <Text variant={'paragraph1'} width={'100%'} text={t('therapist_subscription_8')} textAlign={'center'} />
        <Flex width={'100%'} flexDirection={'row'} justifyContent={'space-between'}>
          <Button variant="secondaryDefault" text={t('btn_back')} onClick={backClicked} />
          <Button
            variant="primaryDefault"
            text={t('btn_forward')}
            isLoading={loading}
            disabled={loading || !selectedPackage}
            onClick={nextClicked}
          />
        </Flex>
      </Flex>
    </Card>
  );
};

import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useAppDispatch } from 'src/store';
import { login, setRouteAfterAuthorization } from 'src/store/slices/authSlice';
import { useTranslation } from 'react-i18next';
import { QuestionAnswer } from 'src/types/QuestionAnswer';

export interface LoginFormState {
  email: string;
  password: string;
}
const formDefaultValues: LoginFormState = {
  email: '',
  password: '',
};

const useLoginForm = ({ redirectRoute }) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const [isSubmittingForm, setIsSubmittingForm] = useState(false);

  const useLoginFormchema = yup.object().shape({
    email: yup.string().email(t('error_email')).required(t('error_email_required')),
    password: yup.string().required(t('error_password')).min(6, t('error_password_required')),
  });

  const loginForm = useForm({
    resolver: yupResolver(useLoginFormchema),
    defaultValues: formDefaultValues,
    mode: 'onSubmit',
    reValidateMode: 'onChange',
  });

  const onSubmit = async (data: LoginFormState, outputAnswers: QuestionAnswer[], closeModal: () => void) => {
    setIsSubmittingForm(true);

    if (window.location.pathname.slice(0, 9) === '/details/') {
      const id = window.location.pathname.substring(window.location.pathname.length - 2);
      dispatch(setRouteAfterAuthorization(`/details/${id}`));
    } else if (redirectRoute) {
      dispatch(setRouteAfterAuthorization(redirectRoute));
    }

    await dispatch(
      login({
        email: data.email,
        password: data.password,
        answers: outputAnswers,
      })
    );

    closeModal();
    setIsSubmittingForm(false);
  };

  return {
    formDefaultValues,
    loginForm,
    isSubmittingForm,
    onFormSubmit: onSubmit,
  };
};

export { useLoginForm };

import React, { useContext, useState, useEffect, useMemo } from 'react';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import { useParams, useNavigate } from 'react-router-dom';
import { RootState, useAppSelector, useAppDispatch } from 'src/store';
import { IsMobileContext } from 'src/context/IsMobileContext';
import { Flex } from 'src/components/common';
import { User } from 'src/types/User';
import { RoutesEnum } from 'src/routes';
import { BookSessionDateStep } from './BookSessionDateStep';
import { useTranslation } from 'react-i18next';
import { BookSessionPaymentStep } from './BookSessionPaymentStep';
import { BookSessionPackagesStep } from './BookSessionPackagesStep';
import { ToastNotifications } from 'src/utils/toast-notifications';
import { getPackages } from 'src/store/slices/packagesSlice';
import { userAPI } from 'src/api/user/ApiRequests';
import { appointmentsAPI } from 'src/api/appointments/ApiRequests';
import { parseError } from 'src/utils/error-parser';
import { refreshCurrentUser } from 'src/store/slices/authSlice';
import {
  getBAM,
  getBAMwithDiscount,
  getBAMwithVoucherDiscount,
  getEUR,
  getEURWithDiscount,
  getEURwithVoucherDiscount,
} from 'src/utils/price-calculator';
import { Availability } from 'src/types/Availability';
import { CompanyVoucher } from 'src/types/CompanyVoucher';

export const BookSession = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { isMobile } = useContext(IsMobileContext);
  const { t } = useTranslation();
  const { id } = useParams();
  const { user } = useAppSelector((state: RootState) => state.auth);
  const { packages, selectedPackage } = useAppSelector((state: RootState) => state.packages);

  const [activeStep, setActiveStep] = useState(0);
  const [loading, setLoading] = useState(false);
  const [therapist, setTherapist] = useState<User>();
  const [selectedAvailability, setSelectedAvailability] = useState<Availability>();
  const [voucher, setVoucher] = useState<CompanyVoucher>(null);

  const handleNext = () => {
    window.scroll(0, 0);
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    window.scroll(0, 0);
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const paymentSuccessful = async (orderNumber: string, panToken: string) => {
    setLoading(true);

    try {
      const response = await appointmentsAPI.bookAppointment({
        therapistId: therapist?.id,
        clientId: user?.id,
        availabilityId: selectedAvailability?.id,
        packageId: selectedPackage?.id,
        orderNumber: orderNumber,
        panToken: panToken,
        voucherId: voucher?.id,
      });
      if (response.success) {
        ToastNotifications.success(t('msg_session_booked'), 'bookAppointment');
        await dispatch(refreshCurrentUser());
        navigate(RoutesEnum.DASHBOARD);
      } else {
        ToastNotifications.error(response.error, 'bookAppointment');
      }
    } catch (error) {
      ToastNotifications.error(parseError(error), 'bookAppointment');
    } finally {
      setLoading(false);
    }
  };

  const bookFromPackage = async () => {
    setLoading(true);

    try {
      const response = await appointmentsAPI.bookFromPackageAppointment({
        therapistId: therapist?.id,
        clientId: user?.id,
        availabilityId: selectedAvailability?.id,
      });
      if (response.success) {
        ToastNotifications.success(t('msg_session_booked'), 'bookFromPackage');
        await dispatch(refreshCurrentUser());
        navigate(RoutesEnum.DASHBOARD);
      } else {
        ToastNotifications.error(response.error, 'bookFromPackage');
      }
    } catch (error) {
      ToastNotifications.error(parseError(error), 'bookFromPackage');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    dispatch(getPackages());
  }, []);

  useEffect(() => {
    if (id == null) return;

    const getTherapits = async (id: string) => {
      const response = await userAPI.getUserDetails(id);
      setTherapist(response.data);
    };
    getTherapits(id);
  }, [id]);

  const hasPackage = useMemo(() => {
    return user?.package?.sessions >= 1;
  }, [user]);

  const isInterntionalUser = useMemo(() => {
    return user?.country != 'Bosna i Hercegovina';
  }, [user]);

  // Calcuate price that will be charged from user
  const calculatePrice = useMemo(() => {
    if (voucher != null && selectedPackage.sessions == 1) {
      return getBAMwithVoucherDiscount(selectedPackage, voucher, therapist?.price);
    } else if (selectedPackage?.discount == 0) {
      return getBAMwithDiscount(selectedPackage, therapist?.price);
    } else {
      return getBAM(selectedPackage, therapist?.price);
    }
  }, [selectedPackage, therapist]);

  // Get price for preview based on user location
  // (Note: We are using EUR for all users that are not from BiH)
  const previewPrice = useMemo(() => {
    if (voucher != null && selectedPackage.sessions == 1) {
      return isInterntionalUser
        ? getEURwithVoucherDiscount(selectedPackage, voucher, therapist?.price)
        : getBAMwithVoucherDiscount(selectedPackage, voucher, therapist?.price);
    } else if (selectedPackage?.discount > 0) {
      return isInterntionalUser
        ? getEURWithDiscount(selectedPackage, therapist?.price)
        : getBAMwithDiscount(selectedPackage, therapist?.price);
    } else {
      return isInterntionalUser ? getEUR(selectedPackage, therapist?.price) : getBAM(selectedPackage, therapist?.price);
    }
  }, [selectedPackage, therapist]);

  return (
    <Flex
      width={'100%'}
      padding={isMobile ? '16px' : '24px 10%'}
      flexDirection={'column'}
      gap={'32px'}
      alignItems={'center'}
      justifyContent={'center'}
    >
      {!hasPackage && (
        <Stepper activeStep={activeStep} alternativeLabel>
          <Step key={1}>
            <StepLabel>{`${t('book_therapist_1')} sa ${therapist?.username}`}</StepLabel>
          </Step>
          <Step key={2}>
            <StepLabel>{'Odaberite vaš paket ili pretplatu'}</StepLabel>
          </Step>
          <Step key={3}>
            <StepLabel>{'Uslovi korištenja i plaćanje'}</StepLabel>
          </Step>
        </Stepper>
      )}

      {activeStep == 0 && (
        <BookSessionDateStep
          loading={loading}
          hasPackage={hasPackage}
          therapist={therapist}
          selectedAvailability={selectedAvailability}
          setSelectedAvailability={setSelectedAvailability}
          voucher={voucher}
          setVoucher={setVoucher}
          nextClicked={handleNext}
          bookFromPackage={bookFromPackage}
        />
      )}

      {activeStep == 1 && (
        <BookSessionPackagesStep
          loading={loading}
          therapist={therapist}
          packages={packages}
          selectedPackage={selectedPackage}
          voucher={voucher}
          backClicked={handleBack}
          nextClicked={handleNext}
        />
      )}

      {activeStep == 2 && (
        <BookSessionPaymentStep
          loading={loading}
          amount={calculatePrice}
          orderNumber={voucher != null ? `VOUCHER-${new Date().getTime()}-${voucher.code}` : `PSHT-${new Date().getTime()}-${selectedAvailability.date}`}
          previewPrice={previewPrice}
          currency={isInterntionalUser ? 'EUR' : 'BAM'}
          selectedPackage={selectedPackage}
          backClicked={handleBack}
          paymentSuccessful={paymentSuccessful}
        />
      )}
    </Flex>
  );
};

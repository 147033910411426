import React, { useCallback, useEffect, useState } from 'react';
import { Text, Button, Input, SelectDropdown } from 'src/components/common';
import { InlineTextWrapper } from 'src/components/shared';
import { RegisterFormState, useRegisterForm } from './useRegisterForm';
import { QuestionAnswer } from 'src/types/QuestionAnswer';
import { RolesEnum } from 'src/constants/RolesEnum';
import { COUNTRIES_OPTIONS_FOR_DROPDOWN_BS } from 'src/assets/data/countries-options-for-dropdown';
import { CheckBox, ToggleButtonLabel, ToggleButtonWrapper } from 'src/components/common/toggle-button/styles';
import { citiesAPI } from 'src/api/cities/ApiRequests';
import { RoutesEnum } from 'src/routes';
import { useTheme } from 'styled-components';
import { goToUrl } from 'src/utils/go-to-url';
import { config } from 'src/config/config';
import { useTranslation } from 'react-i18next';

interface RegisterFormProps {
  role: RolesEnum;
  outputAnswers: QuestionAnswer[];
  showLogin: boolean;
  redirectRoute: RoutesEnum;
  toggleLogin: () => void;
}

const RegisterForm = ({ outputAnswers, role, showLogin, redirectRoute, toggleLogin }: RegisterFormProps) => {
  const theme = useTheme();
  const { t } = useTranslation();

  const { registerForm, onFormSubmit, isSubmittingForm } = useRegisterForm({ redirectRoute });
  const { register, handleSubmit, formState, watch, clearErrors, setValue } = registerForm;
  const { errors } = formState;

  const [termsAccepted, setTermsAccepted] = useState(false);
  const [newsletterAccepted, setNewsletterAccepted] = useState(false);

  const countryWatched = watch('country');

  const [cities, setCities] = useState([]);
  const cityWatched = watch('city');

  useEffect(() => {
    if (countryWatched) {
      clearErrors('country');
    }
  }, [countryWatched]);

  useEffect(() => {
    if (cityWatched) {
      clearErrors('city');
    }
  }, [cityWatched]);

  const onSubmit = useCallback(
    async (data: RegisterFormState) => {
      await onFormSubmit(data, outputAnswers, role);
    },
    [outputAnswers, role]
  );

  const loadCities = async (country) => {
    setCities([]);
    setValue('city', '');

    const response = await citiesAPI.getCities(country);

    const options = response?.data
      ? response?.data?.map((city) => {
          return { label: city, value: city };
        })
      : null;

    setCities(options ?? []);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} style={{ width: '100%' }}>
      <Input
        {...register('name')}
        margin="0 0 16px 0"
        placeholder={t('placeholder_name')}
        errorMessage={errors.name?.message}
        width="100%"
      />

      <Input
        {...register('email')}
        margin="0 0 16px 0"
        placeholder={t('placeholder_email')}
        errorMessage={errors.email?.message}
        width="100%"
      />

      <SelectDropdown
        isSearchable={true}
        margin="0 0 16px 0"
        placeholder={t('placeholder_country')}
        errorMessage={errors.country?.message}
        options={COUNTRIES_OPTIONS_FOR_DROPDOWN_BS}
        value={countryWatched}
        width={'100%'}
        onChange={(value: string) => {
          setValue('country', value);
          loadCities(value);
        }}
      />

      {cities && cities.length > 0 && (
        <SelectDropdown
          isSearchable={true}
          margin="0 0 16px 0"
          placeholder={t('placeholder_city')}
          errorMessage={errors.city?.message}
          options={cities}
          value={cityWatched}
          width={'100%'}
          onChange={(value: string) => setValue('city', value)}
        />
      )}

      <Input
        {...register('password')}
        type="password"
        margin="0 0 16px 0"
        placeholder={t('placeholder_password')}
        errorMessage={errors.password?.message}
        width="100%"
      />

      <Input
        {...register('repeatPassword')}
        type="password"
        margin="0 0 32px 0"
        placeholder={t('placeholder_password_repeat')}
        errorMessage={errors.repeatPassword?.message}
        width="100%"
      />

      <ToggleButtonWrapper>
        <CheckBox id="checkbox1" type="checkbox" checked={termsAccepted} onChange={() => setTermsAccepted(!termsAccepted)} />
        <ToggleButtonLabel htmlFor="checkbox1" />
        <InlineTextWrapper margin={'0 0 16px 0'}>
          <Text margin="0 4px 0 0" variant={'paragraph1'} text={t('registration_1')} />
          <Text
            margin="0 4px 0 0"
            variant={'paragraph1'}
            text={t('registration_2')}
            color={theme.colors.palette.purple}
            style={{ textDecoration: 'underline' }}
            onClick={() => goToUrl(config.PRIVACY_POLICY_URL, '_blank')}
          />
        </InlineTextWrapper>
      </ToggleButtonWrapper>

      <ToggleButtonWrapper>
        <CheckBox
          id="checkbox2"
          type="checkbox"
          checked={newsletterAccepted}
          onChange={() => {
            const didAccept = !newsletterAccepted;
            setNewsletterAccepted(didAccept);
            setValue('newsletter', didAccept);
          }}
        />
        <ToggleButtonLabel htmlFor="checkbox2" />
        <Text margin="0 32px 32px 0" variant={'paragraph1'} text={t('registration_3')} />
      </ToggleButtonWrapper>

      <Button
        type="submit"
        variant="primaryDefault"
        text={t('btn_register')}
        minWidth={'200px'}
        isLoading={isSubmittingForm}
        disabled={!termsAccepted && !isSubmittingForm}
        margin={showLogin ? '0 auto 16px auto' : '0 auto'}
      />

      {showLogin && <Text variant="paragraph1" text={t('btn_have_account')} margin={'0 auto'} onClick={toggleLogin} />}
    </form>
  );
};

export default RegisterForm;

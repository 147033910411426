import React, { useContext, useState, useEffect, useRef, useCallback } from 'react';
import { useTheme } from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useNavigate } from 'react-router-dom';
import { IsMobileContext } from 'src/context/IsMobileContext';
import { Flex, Grid, Text } from 'src/components/common';
import { AlertPopup, ChatModal } from 'src/components/shared';
import { RootState, useAppSelector, useAppDispatch } from 'src/store';
import { useDisclosure } from 'src/hooks/useDisclosure';
import { EditProfileModal } from 'src/components/shared/edit-profile-modal/EditProfileModal';
import { Appointment } from 'src/types/Appointment';
import { setActiveTab, setShowDeleteProfileAlert, setShowEditProfileModal } from 'src/store/slices/globalSlice';
import { ClientDashboardTabsEnum } from 'src/constants/ClientDashboardTabsEnum';
import { userAPI } from 'src/api/user/ApiRequests';
import { parseError } from 'src/utils/error-parser';
import { ToastNotifications } from 'src/utils/toast-notifications';
import { User } from 'src/types/User';
import { logout, refreshCurrentUser } from 'src/store/slices/authSlice';
import { setSelectedSession } from 'src/store/slices/sessionSlice';
import { getInbox } from 'src/store/slices/userSlice';
import { RoutesEnum } from 'src/routes';
import { AllSessions } from 'src/pages/dashboard/AllSessions';
import { ClientTherapists } from 'src/pages/dashboard/client/ClientTherapists';
import { AllMessages } from 'src/pages/dashboard/AllMessages';
import { ClientPackages } from 'src/pages/dashboard/client/ClientPackages';
import { UpcomingSession } from 'src/pages/dashboard/UpcomingSession';
import { UnreadMessages } from 'src/pages/dashboard/UnreadMessages';
import { UnpaidSessions } from 'src/pages/dashboard/UnpaidSessions';
import { appointmentsAPI } from 'src/api/appointments/ApiRequests';
import { maxHorizontalPadding } from 'src/constants/common';
import { useTranslation } from 'react-i18next';
import ProfilePhotoPlaceholder from 'src/assets/images/profile-avatar.png';
import Star from 'src/assets/images/star.svg';

export const ClientDashboard = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const { t } = useTranslation();
  const { isMobile } = useContext(IsMobileContext);
  const { user } = useAppSelector((state: RootState) => state.auth);
  const { showEditProfileModal, showDeleteProfileAlert, activeTab, dashboardNavItems } = useAppSelector((state: RootState) => state.global);

  const { isOpen: isChatModalOpen, close: closeChatModal, open: openChatModal } = useDisclosure(false);

  const [selectedUserForChat, setSelectedUserForChat] = useState(undefined);
  const [deleteSessionAlert, setDeleteSessionAlert] = useState(null);

  const imgRef = useRef<HTMLImageElement>();
  const [imgHeight, setImgHeight] = useState<any>('auto');

  useEffect(() => {
    dispatch(getInbox());

    return () => {
      dispatch(setActiveTab(ClientDashboardTabsEnum.HOME));
    };
  }, []);

  const onImgRefChange = useCallback(
    (node) => {
      if (node !== null && node.width) {
        setImgHeight(node.width);
      }
    },
    [imgRef, isMobile]
  );

  const startZoomSession = async (appointment: Appointment) => {
    dispatch(setSelectedSession(appointment));
    navigate(RoutesEnum.ZOOM_SESSION);
  };

  const startChat = (user: User) => {
    setSelectedUserForChat(user);
    openChatModal();
  };

  const deleteProfile = async () => {
    try {
      const response: any = await userAPI.deleteProfile();
      if (response.success) {
        dispatch(logout());
      }
    } catch (error) {
      const err = parseError(error);
      ToastNotifications.error(err, 'deleteProfile');
      dispatch(setShowDeleteProfileAlert(false));
    }
  };

  const cancelSession = async (session: Appointment) => {
    try {
      const response: any = await appointmentsAPI.cencelAppointment(session.id);
      if (response.success) {
        hideDeleteSessionAlert();
        ToastNotifications.success(response.data);
        dispatch(refreshCurrentUser());
      }
    } catch (error) {
      const err = parseError(error);
      ToastNotifications.error(err, 'cancelSession');
      hideDeleteSessionAlert();
    }
  };

  const showCancelSessionAlert = (session: Appointment) => {
    setDeleteSessionAlert(
      <AlertPopup
        title={t('cancel_session_1')}
        message={t('cancel_session_2')}
        confirmAction={() => cancelSession(session)}
        cancelAction={hideDeleteSessionAlert}
      />
    );
  };

  const hideDeleteSessionAlert = () => {
    setDeleteSessionAlert(null);
  };

  return (
    <>
      {selectedUserForChat && <ChatModal isOpen={isChatModalOpen} onClose={closeChatModal} targetUser={selectedUserForChat} />}

      {showDeleteProfileAlert && (
        <AlertPopup
          message={t('delete_profile_1')}
          confirmAction={deleteProfile}
          cancelAction={() => {
            dispatch(setShowDeleteProfileAlert(false));
          }}
        />
      )}

      <EditProfileModal
        profileData={user}
        isOpen={showEditProfileModal}
        onClose={() => {
          dispatch(setShowEditProfileModal(false));
        }}
      />

      <Flex
        flexDirection="column"
        alignItems={isMobile ? 'flex-start' : 'center'}
        justifyContent={isMobile ? 'flex-start' : 'center'}
        width={'100%'}
        flex={'1'}
        position={'relative'}
        padding={isMobile ? '20px' : `50px ${maxHorizontalPadding}`}
        backgroundColor={theme.colors.background.secondary.BG1}
      >
        <Grid gridTemplateColumns={isMobile ? '1fr' : '1fr 4fr'} gridTemplateRows={'1fr'} width={'100%'} gap={'40px'}>
          {!isMobile && (
            <Flex flexDirection="column" width={'100%'} gap={'20px'}>
              <Flex
                flexDirection="column"
                width={'100%'}
                gap="16px"
                alignItems="center"
                padding="0 0 20px 0"
                borderBottom={`1px solid ${theme.colors.border.darker}`}
              >
                <img
                  ref={onImgRefChange}
                  src={user?.photo ?? ProfilePhotoPlaceholder}
                  alt=""
                  width={isMobile ? '75%' : '90%'}
                  height={imgHeight ?? 'auto'}
                  style={{ objectFit: 'cover', borderRadius: '50%' }}
                />
                <Text variant={'heading7'} text={user?.username} />
                <Text variant={'paragraph1'} text={user?.email} />

                {user.package && (
                  <Flex width={'100%'} flexDirection={'row'} justifyContent={'center'} alignItems={'center'} gap={'8px'}>
                    <img src={Star} width={'24px'} height={'24px'} alt="" />
                    <Text variant={'paragraph1'} text={`Preostale sesije: ${user.package?.sessions}`} />
                  </Flex>
                )}
              </Flex>

              <Flex width={'100%'} flexDirection={'column'} padding={isMobile ? '0 0 20px 0' : 'none'}>
                {dashboardNavItems?.map((nav, index) => (
                  <Flex
                    key={index}
                    onClick={nav.action}
                    width={'100%'}
                    padding={'12px 0'}
                    borderRadius={'20px'}
                    alignItems={'center'}
                    backgroundColor={nav.tab === activeTab ? theme.colors.palette.purple : theme.colors.palette.transparent}
                  >
                    <FontAwesomeIcon
                      size="lg"
                      width={50}
                      height={40}
                      icon={nav.icon}
                      color={nav.tab === activeTab ? theme.colors.palette.white : theme.colors.palette.black}
                    />
                    <Text
                      variant={'paragraph1'}
                      text={nav.title}
                      color={nav.tab === activeTab ? theme.colors.palette.white : theme.colors.palette.black}
                    />
                  </Flex>
                ))}
              </Flex>
            </Flex>
          )}

          {activeTab === ClientDashboardTabsEnum.HOME && (
            <Flex flexDirection={'column'} width={'100%'} minHeight={'85vh'} gap={'40px'} overflow={'scroll'}>
              <UnreadMessages />
              <UnpaidSessions cancelSession={(session) => showCancelSessionAlert(session)} />
              <UpcomingSession
                startZoomSession={(session) => startZoomSession(session)}
                startChat={(session) => startChat(session.therapist)}
                cancelSession={(session) => showCancelSessionAlert(session)}
              />
            </Flex>
          )}

          {activeTab === ClientDashboardTabsEnum.MY_SESSIONS && (
            <AllSessions
              startZoomSession={(session) => startZoomSession(session)}
              startChat={(session) => startChat(session.therapist)}
              cancelSession={(session) => showCancelSessionAlert(session)}
              bookSession={() => dispatch(setActiveTab(ClientDashboardTabsEnum.MY_THERAPISTS))}
            />
          )}

          {activeTab === ClientDashboardTabsEnum.MY_THERAPISTS && <ClientTherapists />}

          {activeTab === ClientDashboardTabsEnum.MY_MESSAGES && <AllMessages />}

          {activeTab === ClientDashboardTabsEnum.PACKAGES && <ClientPackages />}
        </Grid>
      </Flex>

      {deleteSessionAlert}
    </>
  );
};

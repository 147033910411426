import styled from 'styled-components';

export const AccordionSection = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const AccordionItemWrap = styled.div`
  width: 100%;
  padding: ${(props) => (props.padding ? props.padding : '')};
  border-bottom: ${(props) => (props.borderSize ? props.borderSize : '1px')} solid ${(props) => props.theme.colors.palette.black};
  margin-bottom: ${(props) => (props.marginBottom ? props.marginBottom : '24px')};
`;

export const AccordionTitleWrap = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 0 0 12px 0;
  cursor: pointer !important;

  svg {
    height: 20px;
    width: 20px;
    cursor: pointer !important;
    transition: all 0.25s;

    &.expanded {
      transform: rotate(180deg);
      transition: all 0.25s;
    }
  }
`;

export const Dropdown = styled.div`
  width: 95%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: inherit;
  overflow: hidden;
  max-height: 0;
  transition: max-height 0.25s ease-out;

  &.show {
    max-height: 1000px;
    transition: max-height 0.25s ease-in;
  }

  @media only screen and (max-width: 768px) {
    width: 100%;
  }
`;

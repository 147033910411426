import React, { useState, useCallback } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { useTheme } from 'styled-components';
import { Button, Flex, Input, Text } from 'src/components/common';
import { LoginFormState, useLoginForm } from './useLoginForm';
import { config } from 'src/config/config';
import { useTranslation } from 'react-i18next';
import { QuestionAnswer } from 'src/types/QuestionAnswer';
import { RoutesEnum } from 'src/routes';

interface FormProps {
  outputAnswers: QuestionAnswer[];
  redirectRoute: RoutesEnum;
  closeModal: () => void;
  showResetBtn: boolean;
  toggleReset: () => void;
  showRegisrationBtn: boolean;
  toggleRegistration: () => void;
}

const LoginForm = ({
  outputAnswers,
  redirectRoute,
  closeModal,
  toggleReset,
  showResetBtn,
  showRegisrationBtn,
  toggleRegistration,
}: FormProps) => {
  const theme = useTheme();

  const { t } = useTranslation();
  const { loginForm, onFormSubmit, isSubmittingForm } = useLoginForm({ redirectRoute });
  const { register, handleSubmit, formState } = loginForm;
  const { errors } = formState;
  const [captchaResponse, setCaptchaResponse] = useState(null);
  const [captchaError, setCaptchaError] = useState(null);

  function onChange(value) {
    setCaptchaError(null);
    setCaptchaResponse(value);
  }

  const onSubmit = useCallback(
    async (data: LoginFormState) => {
      if (isSubmittingForm) {
        return;
      }

      setCaptchaError(null);

      if (!captchaResponse) {
        setCaptchaError(t('error_captcha'));
        return;
      }

      await onFormSubmit(data, outputAnswers, closeModal);
    },
    [isSubmittingForm, captchaResponse]
  );

  return (
    <Flex flexDirection={'column'} width={'100%'}>
      <form onSubmit={handleSubmit(onSubmit)} style={{ width: '100%' }}>
        <Input
          {...register('email')}
          margin="0 0 16px 0"
          placeholder={t('placeholder_email')}
          errorMessage={errors.email?.message}
          width="100%"
        />
        <Input
          {...register('password')}
          type="password"
          margin="0 0 16px 0"
          placeholder={t('placeholder_password')}
          errorMessage={errors.password?.message}
          width="100%"
        />

        {showResetBtn && <Text variant={'paragraph1'} text={t('btn_forgot_pasword')} margin="0 0 32px 8px" onClick={toggleReset} />}

        <Flex flexDirection={'column'} alignItems={'center'} width={'100%'}>
          <ReCAPTCHA sitekey={config.RECAPTCHA_SITE_KEY} onChange={onChange} size={'normal'} />

          {captchaError && <Text text={captchaError} variant="paragraph3" margin={'4px 0 0 8px'} color={theme.colors.palette.red} />}

          <Button
            type="submit"
            variant="primaryDefault"
            text={t('btn_login')}
            minWidth={'200px'}
            isLoading={isSubmittingForm}
            margin={'32px 0 16px 0'}
          />

          {showRegisrationBtn && <Text variant="paragraph1" text={t('btn_missing_account')} onClick={toggleRegistration} />}
        </Flex>
      </form>
    </Flex>
  );
};

export default LoginForm;

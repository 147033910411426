import React, { useEffect } from 'react';
import { Routes, Route, useNavigate, useLocation } from 'react-router-dom';
import { DotsSpinner } from 'src/components/shared';
import { GlobalStyles } from 'src/styles/globalStyles';
import { getAppRoutes, isPublicRoute, isRouteAllowed, RoutesEnum } from 'src/routes';
import { RootState, useAppSelector, useAppDispatch } from 'src/store';
import { getCurrentUser, logout, toggleLoginModal, toggleRegistrationModal } from 'src/store/slices/authSlice';
import { setSelectedPlan } from './store/slices/subscriptionsSlice';
import { ClientDashboardTabsEnum } from 'src/constants/ClientDashboardTabsEnum';
import { TherapistDashboardTabsEnum } from 'src/constants/TherapistDashboardTabsEnum';
import { Flex, Text } from './components/common';
import { RolesEnum } from './constants/RolesEnum';
import { Analytics } from '@vercel/analytics/react';
import { useTranslation } from 'react-i18next';
import {
  setShowEditProfileModal,
  setShowRecommendFriendModal,
  setShowTutorialModal,
  setActiveTab,
  setBurgerMenuItems,
  setDashboardNavItems,
} from './store/slices/globalSlice';
import {
  faCalendar,
  faEdit,
  faHome,
  faMessage,
  faMoneyCheckDollar,
  faPhone,
  faUserPlus,
  faUsers,
  faBook,
  faCalendarDays,
} from '@fortawesome/free-solid-svg-icons';
import ProfilePhotoPlaceholder from './assets/images/profile-avatar.png';
import Star from 'src/assets/images/star.svg';
import ScrollToTop from './components/ScrollToTop';

const App = (props: any) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const { pathname } = useLocation();
  const { t } = useTranslation();

  const { isAuthenticated, loading, user } = useAppSelector((state: RootState) => state.auth);

  const checkForDashboardRoute = () => {
    if (window.location.href !== RoutesEnum.DASHBOARD) {
      navigate(RoutesEnum.DASHBOARD);
    }
  };

  const CLIENT_DASHBOARD_NAV_ITEMS = [
    {
      title: t('navigation_1'),
      icon: faHome,
      tab: ClientDashboardTabsEnum.HOME,
      action: () => {
        checkForDashboardRoute();
        dispatch(setActiveTab(ClientDashboardTabsEnum.HOME));
      },
    },
    {
      title: t('navigation_2'),
      icon: faCalendar,
      tab: ClientDashboardTabsEnum.MY_SESSIONS,
      action: () => {
        checkForDashboardRoute();
        dispatch(setActiveTab(ClientDashboardTabsEnum.MY_SESSIONS));
      },
    },
    {
      title: t('navigation_3'),
      icon: faUsers,
      tab: ClientDashboardTabsEnum.MY_THERAPISTS,
      action: () => {
        checkForDashboardRoute();
        dispatch(setActiveTab(ClientDashboardTabsEnum.MY_THERAPISTS));
      },
    },
    {
      title: t('navigation_4'),
      icon: faMessage,
      tab: ClientDashboardTabsEnum.MY_MESSAGES,
      action: () => {
        checkForDashboardRoute();
        dispatch(setActiveTab(ClientDashboardTabsEnum.MY_MESSAGES));
      },
    },
    {
      title: t('navigation_17'),
      icon: faMoneyCheckDollar,
      tab: ClientDashboardTabsEnum.PACKAGES,
      action: () => {
        checkForDashboardRoute();
        dispatch(setActiveTab(ClientDashboardTabsEnum.PACKAGES));
      },
    },
    {
      title: t('navigation_5'),
      icon: faEdit,
      action: () => dispatch(setShowEditProfileModal(true)),
    },
  ];

  const CLIENT_DASHBOARD_BURGER_MENU_ITEMS = [
    {
      id: 0,
      customContent: (
        <Flex flexDirection={'column'} width={'100%'} alignItems={'center'}>
          <img
            src={user?.photo ?? ProfilePhotoPlaceholder}
            alt=""
            style={{
              borderRadius: '50%',
              width: '140px',
              height: '140px',
              objectFit: 'cover',
              marginBottom: 16,
            }}
          />
          <Text variant={'heading7'} text={user?.username} />
          <Text variant={'paragraph2'} text={user?.email} margin={'0 0 16px 0'} />
          {user?.package && user?.package?.sessions > 0 && (
            <Flex width={'100%'} flexDirection={'row'} justifyContent={'center'} alignItems={'center'} gap={'8px'}>
              <img src={Star} width={'24px'} height={'24px'} alt="" />
              <Text variant={'paragraph1'} text={`Preostale sesije: ${user.package?.sessions}`} />
            </Flex>
          )}
        </Flex>
      ),
    },
    ...CLIENT_DASHBOARD_NAV_ITEMS,
  ];

  const THERAPITS_DASHBOARD_NAV_ITEMS = [
    {
      id: 1,
      title: t('navigation_1'),
      icon: faHome,
      tab: TherapistDashboardTabsEnum.HOME,
      action: () => {
        checkForDashboardRoute();
        dispatch(setActiveTab(TherapistDashboardTabsEnum.HOME));
      },
    },
    {
      id: 5,
      title: t('navigation_6'),
      icon: faBook,
      tab: TherapistDashboardTabsEnum.TUTORIAL,
      action: () => dispatch(setShowTutorialModal(true)),
    },
    {
      id: 2,
      title: t('navigation_4'),
      icon: faMessage,
      tab: TherapistDashboardTabsEnum.MY_MESSAGES,
      action: () => {
        checkForDashboardRoute();
        dispatch(setActiveTab(TherapistDashboardTabsEnum.MY_MESSAGES));
      },
    },
    {
      id: 3,
      title: t('navigation_2'),
      icon: faCalendarDays,
      tab: TherapistDashboardTabsEnum.MY_SESSIONS,
      action: () => {
        checkForDashboardRoute();
        dispatch(setActiveTab(TherapistDashboardTabsEnum.MY_SESSIONS));
      },
    },
    {
      id: 4,
      title: t('navigation_7'),
      icon: faUsers,
      tab: TherapistDashboardTabsEnum.MY_CLIENTS,
      action: () => {
        checkForDashboardRoute();
        dispatch(setActiveTab(TherapistDashboardTabsEnum.MY_CLIENTS));
      },
    },
    {
      id: 5,
      title: t('navigation_8'),
      icon: faMoneyCheckDollar,
      tab: TherapistDashboardTabsEnum.SUBSCRIPTIONS,
      action: () => {
        checkForDashboardRoute();
        dispatch(setActiveTab(TherapistDashboardTabsEnum.SUBSCRIPTIONS));
      },
    },
    {
      id: 6,
      title: t('navigation_9'),
      icon: faUserPlus,
      action: () => dispatch(setShowRecommendFriendModal(true)),
    },
    {
      id: 7,
      title: t('navigation_16'),
      icon: faEdit,
      action: () => dispatch(setShowEditProfileModal(true)),
    },
    {
      id: 8,
      title: t('navigation_10'),
      icon: faPhone,
      action: () => navigate(RoutesEnum.CONTACT),
    },
  ];

  const THERAPIST_DASHBOARD_BURGER_MENU_ITEMS = [
    {
      id: 0,
      customContent: (
        <Flex flexDirection={'column'} width={'100%'} alignItems="center">
          <img
            src={user?.photo ?? ProfilePhotoPlaceholder}
            alt=""
            style={{
              borderRadius: '50%',
              width: '140px',
              height: '140px',
              objectFit: 'cover',
              marginBottom: 16,
            }}
          />
          <Text variant={'heading7'} text={user?.username} />
          <Text variant={'paragraph2'} text={user?.email} margin={'0 0 16px 0'} />
          {user?.package && user?.package?.sessions > 0 && (
            <Flex width={'100%'} flexDirection={'row'} justifyContent={'center'} alignItems={'center'} gap={'8px'}>
              <img src={Star} width={'24px'} height={'24px'} alt="" />
              <Text variant={'paragraph1'} text={`Preostale sesije: ${user.package?.sessions}`} />
            </Flex>
          )}
        </Flex>
      ),
    },
    ...THERAPITS_DASHBOARD_NAV_ITEMS,
  ];

  useEffect(() => {
    if (!isAuthenticated && !isPublicRoute(pathname)) {
      return navigate(RoutesEnum.HOME);
    }

    if (isAuthenticated) {
      if (!user) {
        dispatch(getCurrentUser());
      }

      if (user) {
        // Redirect user to specific route after authorisation
        const routeAfterAuthorization = JSON.parse(localStorage.getItem('routeAfterAuthorization'));
        if (routeAfterAuthorization && isRouteAllowed(routeAfterAuthorization, user?.role, t)) {
          navigate(routeAfterAuthorization);
          dispatch(toggleLoginModal(false));
          dispatch(toggleRegistrationModal(false));
          localStorage.removeItem('routeAfterAuthorization');
        }

        if (!isRouteAllowed(pathname, user?.role, t)) {
          navigate(RoutesEnum.FORBIDDEN);
        }

        if (user?.role === RolesEnum.THERAPIST) {
          dispatch(setBurgerMenuItems(THERAPIST_DASHBOARD_BURGER_MENU_ITEMS));
          dispatch(setDashboardNavItems(THERAPITS_DASHBOARD_NAV_ITEMS));
        } else {
          dispatch(setBurgerMenuItems(CLIENT_DASHBOARD_BURGER_MENU_ITEMS));
          dispatch(setDashboardNavItems(CLIENT_DASHBOARD_NAV_ITEMS));
        }
      }
    }
  }, [isAuthenticated, location, user, pathname, isRouteAllowed]);

  useEffect(() => {
    window.addEventListener(
      'storage',
      async (event) => {
        if ((event.key === 'token' && event.newValue === null) || (event.key === null && event.newValue === null)) {
          await dispatch(logout());
        }
        if ((event.key === 'selectedPlan' && event.newValue === null) || (event.key === null && event.newValue === null)) {
          dispatch(setSelectedPlan(null));
        }
        if ((event.key === 'activeTab' && event.newValue === null) || (event.key === null && event.newValue === null)) {
          dispatch(setActiveTab(1));
        }
      },
      false
    );
    // eslint-disable-next-line
  }, [window]);

  useEffect(() => {
    if (location.pathname !== RoutesEnum.DASHBOARD) {
      dispatch(setActiveTab(1));
    }
    // eslint-disable-next-line
  }, [location.pathname]);

  return (
    <>
      <Analytics />
      <GlobalStyles />
      <ScrollToTop />
      <Routes>
        {getAppRoutes(t).map(({ path, Layout, Component }, index) => (
          <Route
            key={index}
            path={path}
            element={<Layout {...props}>{loading ? <DotsSpinner inline style={{ margin: 'auto' }} /> : <Component />}</Layout>}
          />
        ))}
      </Routes>
    </>
  );
};

export default App;

import React, { useContext, useState, useEffect, useRef, useCallback } from 'react';
import ProfilePhotoPlaceholder from 'src/assets/images/profile-avatar.png';
import { useTheme } from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useNavigate } from 'react-router-dom';
import { EditProfileModal } from 'src/components/shared/edit-profile-modal/EditProfileModal';
import { RecommendFriendModal } from './modals/RecommendFriendModal';
import { IsMobileContext } from 'src/context/IsMobileContext';
import { Flex, Grid, Text } from 'src/components/common';
import { AlertPopup, ChatModal } from 'src/components/shared';
import { useDisclosure } from 'src/hooks/useDisclosure';
import { Appointment } from 'src/types/Appointment';
import { TherapistDashboardTabsEnum } from 'src/constants/TherapistDashboardTabsEnum';
import { User } from 'src/types/User';
import { userAPI } from 'src/api/user/ApiRequests';
import { RootState, useAppSelector, useAppDispatch } from 'src/store';
import { getInbox } from 'src/store/slices/userSlice';
import { logout, refreshCurrentUser } from 'src/store/slices/authSlice';
import { setSelectedSession } from 'src/store/slices/sessionSlice';
import { RoutesEnum } from 'src/routes';
import { ToastNotifications } from 'src/utils/toast-notifications';
import { parseError } from 'src/utils/error-parser';
import { AllSessions } from 'src/pages/dashboard/AllSessions';
import { AllMessages } from 'src/pages/dashboard/AllMessages';
import { TherapistClients } from 'src/pages/dashboard/therapist/TherapistClients';
import { UpcomingSession } from 'src/pages/dashboard/UpcomingSession';
import { TherapistCalendar } from 'src/pages/dashboard/therapist/TherapistCalendar';
import { TherapistStatistics } from 'src/pages/dashboard/therapist/TherapistStatistics';
import { TherapistSubscriptions } from 'src/pages/dashboard/therapist/TherapistSubscriptions';
import { UnreadMessages } from 'src/pages/dashboard/UnreadMessages';
import { setSelectedPlan } from 'src/store/slices/subscriptionsSlice';
import { appointmentsAPI } from 'src/api/appointments/ApiRequests';
import { maxHorizontalPadding } from 'src/constants/common';
import { TutorialModal } from './modals/TutorialModal';
import { useTranslation } from 'react-i18next';
import {
  setActiveTab,
  setShowDeleteProfileAlert,
  setShowEditProfileModal,
  setShowRecommendFriendModal,
  setShowTutorialModal,
} from 'src/store/slices/globalSlice';

export const TherapistDashboard = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const imgRef = useRef<HTMLImageElement>();

  const { t } = useTranslation();
  const { isMobile } = useContext(IsMobileContext);
  const { user } = useAppSelector((state: RootState) => state.auth);

  const { activeTab, dashboardNavItems, showRecommendFriendModal, showEditProfileModal, showDeleteProfileAlert, showTutorialModal } = useAppSelector((state: RootState) => state.global);
  const { isOpen: isChatModalOpen, close: closeChatModal, open: openChatModal } = useDisclosure(false);

  const [selectedUserForChat, setSelectedUserForChat] = useState(undefined);
  const [deleteSessionAlert, setDeleteSessionAlert] = useState(null);
  const [imgHeight, setImgHeight] = useState<any>('auto');

  const onImgRefChange = useCallback(
    (node) => {
      if (node !== null && node.width) {
        setImgHeight(node.width);
      }
    },
    [imgRef, isMobile]
  );

  const startZoomSession = async (appointment: Appointment) => {
    dispatch(setSelectedSession(appointment));
    navigate(RoutesEnum.ZOOM_SESSION);
  };

  const startChat = (user: User) => {
    setSelectedUserForChat(user);
    openChatModal();
  };

  const deleteProfile = async () => {
    try {
      const response: any = await userAPI.deleteProfile();
      if (response.success) {
        dispatch(logout());
      }
    } catch (error) {
      const err = parseError(error);
      ToastNotifications.error(err, 'deleteProfile');
      dispatch(setShowDeleteProfileAlert(false));
    }
  };

  const cancelSession = async (session: Appointment) => {
    try {
      const response: any = await appointmentsAPI.cencelAppointment(session.id);
      if (response.success) {
        hideDeleteSessionAlert();
        ToastNotifications.success(response.data);
        dispatch(refreshCurrentUser());
      }
    } catch (error) {
      const err = parseError(error);
      ToastNotifications.error(err, 'cancelSession');
      hideDeleteSessionAlert();
    }
  };

  const showCancelSessionAlert = (session: Appointment) => {
    setDeleteSessionAlert(
      <AlertPopup
        title={t('cancel_session_1')}
        message={t('cancel_session_3')}
        confirmAction={() => cancelSession(session)}
        cancelAction={hideDeleteSessionAlert}
      />
    );
  };

  const hideDeleteSessionAlert = () => {
    setDeleteSessionAlert(null);
  };

  useEffect(() => {
    dispatch(getInbox());

    return () => {
      dispatch(setSelectedPlan(undefined));
      dispatch(setActiveTab(TherapistDashboardTabsEnum.HOME));
    };
  }, []);

  return (
    <>
      <Flex
        id="meetingSDKElement"
        flexDirection="column"
        alignItems={isMobile ? 'flex-start' : 'center'}
        justifyContent={isMobile ? 'flex-start' : 'center'}
        width={'100%'}
        flex={'1'}
        position={'relative'}
        padding={isMobile ? '20px' : `48px ${maxHorizontalPadding}`}
        backgroundColor={theme.colors.background.secondary.BG1}
      >
        {selectedUserForChat && <ChatModal isOpen={isChatModalOpen} onClose={closeChatModal} targetUser={selectedUserForChat} />}

        {showDeleteProfileAlert && (
          <AlertPopup
            message={t('delete_profile_1')}
            confirmAction={deleteProfile}
            cancelAction={() => {
              dispatch(setShowDeleteProfileAlert(false));
            }}
          />
        )}

        <EditProfileModal
          isOpen={showEditProfileModal}
          onClose={() => {
            dispatch(setShowEditProfileModal(false));
          }}
          profileData={user}
        />

        <RecommendFriendModal
          isOpen={showRecommendFriendModal}
          onClose={() => {
            dispatch(setShowRecommendFriendModal(false));
          }}
        />

        <TutorialModal
          isOpen={showTutorialModal}
          onClose={() => {
            dispatch(setShowTutorialModal(false));
          }}
        />

        <Grid gridTemplateColumns={isMobile ? '1fr' : '1fr 4fr'} gridTemplateRows={'1fr'} width={'100%'} gap={isMobile ? '10px' : '40px'}>
          {!isMobile && (
            <Flex flexDirection="column" width={'100%'} gap={'20px'}>
              <Flex
                flexDirection="column"
                width={'100%'}
                gap="16px"
                alignItems="center"
                padding="0 0 20px 0"
                borderBottom={`1px solid ${theme.colors.border.darker}`}
              >
                <img
                  ref={onImgRefChange}
                  src={user?.photo ?? ProfilePhotoPlaceholder}
                  alt="Profile"
                  width={isMobile ? '75%' : '90%'}
                  height={imgHeight ?? 'auto'}
                  style={{ objectFit: 'cover', borderRadius: '50%' }}
                />
                <Text variant={'heading7'} text={user?.username} />
                <Text variant={'paragraph1'} text={user?.email} />
              </Flex>

              <Flex
                width={'100%'}
                flexDirection={'column'}
                padding={isMobile ? '0 0 20px 0' : 'none'}
                borderBottom={isMobile ? `1px solid ${theme.colors.palette.black}` : 'none'}
              >
                {dashboardNavItems?.map((nav, index) => (
                  <Flex
                    key={index}
                    onClick={nav.action}
                    width={'100%'}
                    padding={'12px 0'}
                    borderRadius={'20px'}
                    alignItems={'center'}
                    backgroundColor={nav.tab === activeTab ? theme.colors.palette.purple : theme.colors.palette.transparent}
                  >
                    <FontAwesomeIcon
                      size="lg"
                      width={50}
                      height={30}
                      icon={nav.icon}
                      color={nav.tab === activeTab ? theme.colors.palette.white : theme.colors.palette.black}
                    />
                    <Text
                      variant={'paragraph1'}
                      text={nav.title}
                      color={nav.tab === activeTab ? theme.colors.palette.white : theme.colors.palette.black}
                    />
                  </Flex>
                ))}
              </Flex>
            </Flex>
          )}

          <Flex
            flexDirection="column"
            width={'100%'}
            minHeight={isMobile ? 'unset' : '80vh'}
            gap={'40px'}
            padding={isMobile ? '20px 0' : '20px'}
            borderRadius={'20px'}
            justifyContent={'center'}
            alignItems={'center'}
            overflow={'auto'}
          >
            {activeTab === TherapistDashboardTabsEnum.HOME && (
              <Flex flexDirection="column" width={'100%'} gap={'32px'}>
                <UnreadMessages />

                <UpcomingSession
                  startZoomSession={(session) => startZoomSession(session)}
                  startChat={(session) => startChat(session.client)}
                  cancelSession={(session) => showCancelSessionAlert(session)}
                />

                <TherapistCalendar />

                <TherapistStatistics />
              </Flex>
            )}

            {activeTab === TherapistDashboardTabsEnum.MY_MESSAGES && <AllMessages />}

            {activeTab === TherapistDashboardTabsEnum.MY_SESSIONS && (
              <AllSessions
                startZoomSession={(appointment) => startZoomSession(appointment)}
                startChat={(appointment) => startChat(appointment.client)}
                cancelSession={(session) => showCancelSessionAlert(session)}
                bookSession={() => {}}
              />
            )}

            {activeTab === TherapistDashboardTabsEnum.MY_CLIENTS && <TherapistClients startChat={(client) => startChat(client)} />}

            {activeTab === TherapistDashboardTabsEnum.SUBSCRIPTIONS && <TherapistSubscriptions />}
          </Flex>
        </Grid>
      </Flex>

      {deleteSessionAlert}
    </>
  );
};

import React, { useContext, useState } from 'react';
import { RootState, useAppSelector, useAppDispatch } from 'src/store';
import { Button, Flex, Modal, Text, Input } from 'src/components/common';
import { CheckBox, ToggleButtonLabel, ToggleButtonWrapper } from 'src/components/common/toggle-button/styles';
import { InlineTextWrapper } from 'src/components/shared';
import { User } from 'src/types/User';
import { RolesEnum } from 'src/constants/RolesEnum';
import { RoutesEnum } from 'src/routes';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useTheme } from 'styled-components';
import { IsMobileContext } from 'src/context/IsMobileContext';
import { parseError } from 'src/utils/error-parser';
import { ToastNotifications } from 'src/utils/toast-notifications';
import { goToUrl } from 'src/utils/go-to-url';
import { config } from 'src/config/config';
import { refreshCurrentUser } from 'src/store/slices/authSlice';
import { Availability } from 'src/types/Availability';
import { appointmentsAPI } from 'src/api/appointments/ApiRequests';
import { companiesAPI } from 'src/api/companies/ApiRequests';
import { CompanyVoucher } from 'src/types/CompanyVoucher';

interface Props {
  therapist: User;
  availability: Availability;
  isOpen: boolean;
  setVoucher: (voucher: CompanyVoucher) => void;
  onClose: () => void;
}

export const UseVoucherModal = ({ therapist, availability, isOpen, setVoucher, onClose }: Props) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const theme = useTheme();

  const { t } = useTranslation();
  const { isMobile } = useContext(IsMobileContext);
  const { user } = useAppSelector((state: RootState) => state.auth);

  const [loading, setLoading] = useState(false);
  const [voucherCode, setVoucherCode] = useState('');
  const [acceptedTerms, setAcceptedTerms] = useState(user?.role == RolesEnum.THERAPIST);

  async function validateVoucher() {
    setLoading(true);
    try {
      const response = await companiesAPI.validateVoucher({
        voucher: voucherCode,
      });
      if (response.success) {
        if (response.data.discount == 100) {
          bookAppointment();
        } else {
          setVoucher(response.data);
          onClose();
        }
      } else {
        ToastNotifications.error(parseError(response.error), 'validateVoucher');
      }
    } catch (error) {
      ToastNotifications.error(parseError(error), 'validateVoucher');
    } finally {
      setLoading(false);
    }
  }

  async function bookAppointment() {
    try {
      setLoading(true);
      const response = await appointmentsAPI.bookAppointmentWithVoucher({
        therapistId: therapist.id,
        clientId: user.id,
        availabilityId: availability.id,
        voucher: voucherCode,
      });
      if (response.success) {
        ToastNotifications.success(response.data, 'bookAppointment');
        await dispatch(refreshCurrentUser());
        navigate(RoutesEnum.DASHBOARD);
        onClose();
      }
    } catch (error) {
      ToastNotifications.error(parseError(error), 'bookAppointment');
    } finally {
      setLoading(false);
    }
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="medium">
      <Flex flexDirection={'column'} width={'100%'} alignItems={'center'} gap={'10px'}>
        <Text
          variant="heading7"
          text={t('hint_voucher')}
          margin={'32px 0 32px 0'}
          width={'100%'}
          textAlign={'center'}
          whiteSpace={'pre-wrap'}
        />
        <Input width={'100%'} value={voucherCode} onChange={(e) => setVoucherCode(e.target.value)} />

        <ToggleButtonWrapper style={{ marginBottom: 24, marginTop: 16 }}>
          <CheckBox id="checkbox" type="checkbox" checked={acceptedTerms} onChange={() => setAcceptedTerms(!acceptedTerms)} />
          <ToggleButtonLabel htmlFor="checkbox" />
          <InlineTextWrapper>
            <Text margin="0 4px 0 0" variant={'paragraph1'} text={t('book_therapist_2')} />
            <Text
              margin="0 4px 0 0"
              variant={'paragraph1'}
              text={t('book_therapist_3')}
              color={theme.colors.palette.purple}
              style={{ textDecoration: 'underline' }}
              onClick={() => goToUrl(config.PAYMENT_POLICY_URL, '_blank')}
            />
          </InlineTextWrapper>
        </ToggleButtonWrapper>

        <Button
          width={isMobile ? '100%' : 'auto'}
          minWidth="250px"
          variant={'accentDefault'}
          text={t('btn_use_voucher')}
          isLoading={loading}
          disabled={loading || !acceptedTerms || voucherCode.length == 0}
          onClick={validateVoucher}
        />
      </Flex>
    </Modal>
  );
};

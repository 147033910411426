import React, { useMemo } from 'react';
import { useTheme } from 'styled-components';

import { ButtonProps } from 'src/types/Button';
import { Text } from 'src/components/common';
import { Spinner } from 'src/components/shared';
import { StyledButton, LeftIconWrapper, RightIconWrapper, getStyles } from './styles';

export const Button = (props: ButtonProps) => {
  const theme = useTheme();

  const {
    variant = 'primaryDefault',
    text = 'button text',
    textColor = undefined,
    isLoading = false,
    leftIcon = null,
    rightIcon = null,
    onClick = () => undefined,
    noBorder = false,
    ...rest
  } = props;

  const styles = useMemo(() => {
    return getStyles(variant, theme);
  }, [variant, theme]);

  const internalOnClick = () => {
    if (!isLoading && !props.disabled) {
      onClick();
    }
  };

  return (
    <StyledButton {...rest} styles={styles} noBorder={noBorder} onClick={internalOnClick}>
      {isLoading && <Spinner inline={true} size={24} color={theme.colors.palette.black} />}
      {leftIcon && !isLoading && <LeftIconWrapper>{leftIcon}</LeftIconWrapper>}
      {!isLoading && (
        <Text variant={styles.typographyVariant} text={text} color={textColor ?? styles.color} textAlign={'center'} width="max-content" />
      )}
      {rightIcon && !isLoading && <RightIconWrapper>{rightIcon}</RightIconWrapper>}
    </StyledButton>
  );
};

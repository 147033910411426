import { FunctionComponent } from 'react';
import { matchPath } from 'react-router-dom';
import _ from 'underscore';
import { AppLayout } from 'src/components/core';
import {
  Forbidden,
  Home,
  AboutUs,
  Contact,
  ForTherapists,
  ForCompanies,
  FAQ,
  NotFound,
  QuestionnaireForIndividuals,
  QuestionnaireForChildren,
  SuggestedTherapists,
  TherapistDetails,
  Dashboard,
  Session,
  PrivacyPolicy,
  TermsOfUse,
  PaymentPolicy,
} from 'src/pages';
import { RolesEnum } from 'src/constants/RolesEnum';
import { BookSession } from 'src/pages/book-session/BookSesion';

export enum RoutesEnum {
  HOME = '/',
  DASHBOARD = '/dashboard',
  ABOUT_US = '/about',
  FOR_THERAPISTS = '/therapists',
  FOR_COMPANIES = '/companies',
  FAQ = '/faq',
  CONTACT = '/contact',
  QUESTIONNAIRE_FOR_INDIVIDUALS = '/questionnaire/individuals',
  QUESTIONNAIRE_FOR_CHILDREN_ADOLESCENTS = '/questionnaire/children/adolescents',
  SUGGESTED_THERAPISTS = '/matches',
  THERAPIST_DETAILS = '/details/:id',
  FORBIDDEN = '/forbidden',
  ZOOM_SESSION = '/session',
  PRIVACY_POLICY = '/privacy-policy',
  TERMS_OF_USE = '/terms-of-use',
  PAYMENT_POLICY = '/payment-policy',
  BOOK_SESSION = '/book-session/:id',
  NOT_FOUND = '*',
}

interface Route {
  name: string;
  path?: string;
  inHeader?: boolean;
  roles?: RolesEnum[];
  inBurgerMenu?: boolean;
  Component: FunctionComponent;
  Layout: FunctionComponent;
}

export function getAppRoutes(t): Route[] {
  return [
    {
      name: t('navigation_1'),
      path: RoutesEnum.HOME,
      Component: Home,
      Layout: AppLayout,
    },
    {
      name: t('navigation_11'),
      path: RoutesEnum.FOR_THERAPISTS,
      inHeader: true,
      inBurgerMenu: true,
      roles: [RolesEnum.THERAPIST],
      Component: ForTherapists,
      Layout: AppLayout,
    },
    {
      name: t('navigation_12'),
      path: RoutesEnum.FOR_COMPANIES,
      inHeader: true,
      inBurgerMenu: true,
      Component: ForCompanies,
      Layout: AppLayout,
    },
    {
      name: t('navigation_13'),
      path: RoutesEnum.FAQ,
      inHeader: true,
      inBurgerMenu: true,
      Component: FAQ,
      Layout: AppLayout,
    },
    {
      name: t('navigation_14'),
      path: RoutesEnum.ABOUT_US,
      inHeader: true,
      inBurgerMenu: true,
      Component: AboutUs,
      Layout: AppLayout,
    },
    {
      name: t('navigation_15'),
      path: RoutesEnum.CONTACT,
      inHeader: true,
      inBurgerMenu: true,
      Component: Contact,
      Layout: AppLayout,
    },
    {
      name: 'Upitnik - individual',
      path: RoutesEnum.QUESTIONNAIRE_FOR_INDIVIDUALS,
      roles: [RolesEnum.CLIENT_CHILDREN, RolesEnum.CLIENT_INDIVIDUAL],
      Component: QuestionnaireForIndividuals,
      Layout: AppLayout,
    },
    {
      name: 'Upitnik - za djecu (adolescente)',
      path: RoutesEnum.QUESTIONNAIRE_FOR_CHILDREN_ADOLESCENTS,
      roles: [RolesEnum.CLIENT_CHILDREN, RolesEnum.CLIENT_INDIVIDUAL],
      Component: QuestionnaireForChildren,
      Layout: AppLayout,
    },
    {
      name: 'Predlożeni terapisti',
      path: RoutesEnum.SUGGESTED_THERAPISTS,
      roles: [RolesEnum.CLIENT_CHILDREN, RolesEnum.CLIENT_INDIVIDUAL],
      Component: SuggestedTherapists,
      Layout: AppLayout,
    },
    {
      name: 'O terapeutu',
      path: RoutesEnum.THERAPIST_DETAILS,
      Component: TherapistDetails,
      Layout: AppLayout,
    },
    {
      name: 'Dashboard',
      path: RoutesEnum.DASHBOARD,
      roles: [RolesEnum.CLIENT_CHILDREN, RolesEnum.CLIENT_INDIVIDUAL, RolesEnum.THERAPIST],
      Component: Dashboard,
      Layout: AppLayout,
    },
    {
      name: 'Forbidden',
      path: RoutesEnum.FORBIDDEN,
      Component: Forbidden,
      Layout: AppLayout,
    },
    {
      name: 'Not Found',
      path: RoutesEnum.NOT_FOUND,
      Component: NotFound,
      Layout: AppLayout,
    },
    {
      name: 'Session',
      path: RoutesEnum.ZOOM_SESSION,
      roles: [RolesEnum.THERAPIST, RolesEnum.CLIENT_CHILDREN, RolesEnum.CLIENT_INDIVIDUAL],
      Component: Session,
      Layout: AppLayout,
    },
    {
      name: 'Privacy Policy',
      path: RoutesEnum.PRIVACY_POLICY,
      Component: PrivacyPolicy,
      Layout: AppLayout,
    },
    {
      name: 'Terms of Use',
      path: RoutesEnum.TERMS_OF_USE,
      Component: TermsOfUse,
      Layout: AppLayout,
    },
    {
      name: 'Payment policy',
      path: RoutesEnum.PAYMENT_POLICY,
      Component: PaymentPolicy,
      Layout: AppLayout,
    },
    {
      name: 'Book session',
      path: RoutesEnum.BOOK_SESSION,
      roles: [RolesEnum.CLIENT_CHILDREN, RolesEnum.CLIENT_INDIVIDUAL],
      Component: BookSession,
      Layout: AppLayout,
    },
  ];
}

const publicRoutesArray = [
  RoutesEnum.HOME,
  RoutesEnum.FOR_COMPANIES,
  RoutesEnum.ABOUT_US,
  RoutesEnum.FAQ,
  RoutesEnum.CONTACT,
  RoutesEnum.FOR_THERAPISTS,
  RoutesEnum.QUESTIONNAIRE_FOR_CHILDREN_ADOLESCENTS,
  RoutesEnum.QUESTIONNAIRE_FOR_INDIVIDUALS,
  RoutesEnum.THERAPIST_DETAILS,
  RoutesEnum.PRIVACY_POLICY,
  RoutesEnum.TERMS_OF_USE,
  RoutesEnum.PAYMENT_POLICY,
];

export const isPublicRoute = (pathname: string) => {
  return !!publicRoutesArray.find((route) => matchPath(route, pathname));
};

export const isRouteAllowed = (pathname: string, roleToCheck: RolesEnum, t) => {
  const routeToCheck = getAppRoutes(t).find((route) => matchPath(route.path, pathname));

  if (routeToCheck && !Object.prototype.hasOwnProperty.call(routeToCheck, 'roles')) {
    return true;
  }

  return _.contains(routeToCheck.roles, roleToCheck);
};

import { httpService } from 'src/config/http';
import { SubscriptionPlan } from 'src/types/SubscriptionPlan';
import { ApiResponse } from 'src/types/ApiResponse';
import { ISubscribeRequestDTO, ISubscribeWithReferralRequestDTO } from './RequestDtos';

export default class SubscriptionsAPI {
  public getAllSubscriptionPlans() {
    return httpService.get<ApiResponse<SubscriptionPlan[]>>('/subscriptions/all');
  }

  public subscribe(data: ISubscribeRequestDTO) {
    return httpService.post<ApiResponse<any>>(`/subscriptions/subscribe`, data);
  }

  public subscribeWithReferral(data: ISubscribeWithReferralRequestDTO) {
    return httpService.post<ApiResponse<any>>(`/subscriptions/subscribe/referral`, data);
  }

  public cancelSubscription() {
    return httpService.post<ApiResponse<any>>('/subscriptions/cancel');
  }
}

export const subscriptionsAPI = new SubscriptionsAPI();

import React, { useCallback, useContext, useRef, useState } from 'react';
import { RootState, useAppSelector } from 'src/store';
import { IsMobileContext } from 'src/context/IsMobileContext';
import { useTheme } from 'styled-components';
import { Card, Button, Flex, Text } from 'src/components/common';
import { useDisclosure } from 'src/hooks/useDisclosure';
import { BookTherapistModal } from 'src/pages/therapist-details/modals/BookTherapistModal';
import { RoutesEnum } from 'src/routes';
import { User } from 'src/types/User';
import ProfilePhotoPlaceholder from 'src/assets/images/profile-avatar.png';
import { useTranslation } from 'react-i18next';

interface ClientSessionsProps {
  startChat: (client: User) => void;
}

export const TherapistClients = ({ startChat }: ClientSessionsProps) => {
  const theme = useTheme();
  const imgRef = useRef<HTMLImageElement>();
  const { isMobile } = useContext(IsMobileContext);
  const { t } = useTranslation();

  const { isOpen, close, open } = useDisclosure(false);
  const { user } = useAppSelector((state: RootState) => state.auth);

  const [selectedClient, setSelectedClient] = useState(null);
  const [imgHeight, setImgHeight] = useState<any>('auto');

  const onImgRefChange = useCallback(
    (node) => {
      if (node !== null && node.width) {
        setImgHeight(node.width);
      }
    },
    [imgRef, isMobile]
  );

  function showModal(client: User) {
    setSelectedClient(client);
    open();
  }

  function closeModal() {
    setSelectedClient(null);
    close();
  }

  return (
    <Flex flexDirection="column" width={'100%'} gap={'16px'}>
      <Text variant={'heading6'} text={'Moji klijenti'} />

      <Card width={'100%'} borderRadius={'20px'} margin={isMobile ? '0 0 24px 0' : '0 0 48px 0'} padding={'24px'}>
        <Flex flexDirection="column" width={'100%'} height={isMobile ? '65vh' : '75vh'} gap="16px" overflow={'scroll'}>
          {(!user?.clients || user?.clients?.length == 0) && (
            <Flex width={'100%'} height={'100%'} flexDirection={'column'} gap="20px" justifyContent={'center'} alignItems={'center'}>
              <Text variant={'heading6'} text={t('therapist_clients_1')} />
            </Flex>
          )}

          {user?.clients?.map((client, index) => (
            <Flex key={index} flexDirection="column" gap="16px" width="100%">
              <Flex
                width="100%"
                flexDirection={isMobile ? 'column' : 'row'}
                alignItems="center"
                gap={isMobile ? '20px' : '0px'}
                justifyContent="space-between"
              >
                <Flex flexDirection="row" alignItems="center" gap="10px">
                  <img
                    ref={onImgRefChange}
                    src={client?.photo ?? ProfilePhotoPlaceholder}
                    alt=""
                    width={'50px'}
                    height={imgHeight ?? 'auto'}
                    style={{ objectFit: 'cover', borderRadius: '50%' }}
                  />
                  <Text variant={'heading7'} text={client.username} />
                </Flex>

                <Flex flexDirection={'row'} gap="10px">
                  <Button variant={'primaryDefault'} text={t('btn_confirm_session')} onClick={() => showModal(client)} />
                  <Button variant={'secondaryDefault'} text={t('btn_send_message')} onClick={() => startChat(client)} />
                </Flex>
              </Flex>
              <Flex width={'100%'} height={'0px'} border={`0.05px solid ${theme.colors.border.darker}`} />
            </Flex>
          ))}
        </Flex>
      </Card>

      <BookTherapistModal
        isOpen={isOpen}
        onClose={closeModal}
        clientId={selectedClient?.id}
        therapist={user}
        redirectRoute={RoutesEnum.DASHBOARD}
        buttonLabel={t('btn_confirm_timeslot')}
      />
    </Flex>
  );
};

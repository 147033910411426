import React, { useState, useCallback, useContext, useEffect, useRef } from 'react';
import { useParams, useNavigate, generatePath } from 'react-router-dom';
import styled, { useTheme } from 'styled-components';
import ProfilePhotoPlaceholder from 'src/assets/images/profile-avatar.png';
import { useDisclosure } from 'src/hooks/useDisclosure';
import { IsMobileContext } from 'src/context/IsMobileContext';
import { Button, Flex, Grid, Text } from 'src/components/common';
import { RootState, useAppSelector, useAppDispatch } from 'src/store';
import { getTherapistDetails } from 'src/store/slices/userSlice';
import { setRouteAfterAuthorization, toggleRegistrationModal } from 'src/store/slices/authSlice';
import { RoutesEnum } from 'src/routes';
import { useTranslation } from 'react-i18next';
import { FreeSessionModal } from './modals/FreeSessionModal';
import { RolesEnum } from 'src/constants/RolesEnum';
import { ChatModal, InlineTextWrapper } from 'src/components/shared';

const StatisticsCard = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  padding: 24px;
  border-top-left-radius: 50px;
  border-bottom-right-radius: 50px;
  background-color: ${(props) => props.theme.colors.background.secondary.BG4};
`;

export const TherapistDetails = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const imgRef = useRef<HTMLImageElement>();

  const { isAuthenticated } = useAppSelector((state: RootState) => state.auth);
  const { therapistDetails } = useAppSelector((state: RootState) => state.user);
  const { user } = useAppSelector((state: RootState) => state.auth);

  const theme = useTheme();
  const { t } = useTranslation();
  const { isMobile } = useContext(IsMobileContext);
  const [imgHeight, setImgHeight] = useState<any>('auto');

  const { isOpen: showFeeSession, close: closeFreeSessionModal, open: openFreeSessionModal } = useDisclosure(false);
  const { isOpen: isChatModalOpen, close: closeChatModal, open: openChatModal } = useDisclosure(false);

  const onBookSessionClick = useCallback(() => {
    if (isAuthenticated) {
      showBookingScreen(therapistDetails?.id);
      return;
    }

    dispatch(setRouteAfterAuthorization(`book-session/${id}`));
    dispatch(toggleRegistrationModal(true));
  }, [isAuthenticated, therapistDetails]);

  const showBookingScreen = useCallback(
    (id: string | number) => {
      if (!id) return;
      window.location.href = generatePath(RoutesEnum.BOOK_SESSION, { id: id.toString() });
    },
    [navigate]
  );

  const onBackClick = useCallback(() => {
    return isAuthenticated ? navigate(-1) : navigate(RoutesEnum.HOME);
  }, [isAuthenticated]);

  const onImgRefChange = useCallback(
    (node) => {
      if (node !== null && node.width) {
        setImgHeight(node.width);
      }
    },
    [imgRef, isMobile]
  );

  const calculatePrice = () => {
    const bam = Number(therapistDetails?.price ?? 0) + Number(process.env.REACT_APP_PRICE_FEE ?? 0);
    const roundBAM = Number.isInteger(bam) ? bam : bam.toFixed(2);
    const eur = Number(bam) / 1.95;
    const roundEUR = Number.isInteger(eur) ? eur : eur.toFixed(2);
    return user?.country != 'Bosna i Hercegovina' ? `${roundEUR} EUR` : `${roundBAM} BAM`;
  };

  useEffect(() => {
    if (id == null) return;

    dispatch(getTherapistDetails(id));
  }, [id]);

  useEffect(() => {
    if (therapistDetails && therapistDetails?.role !== RolesEnum.THERAPIST) {
      navigate(RoutesEnum.HOME);
    }
  }, [therapistDetails]);

  if (!therapistDetails) {
    return null;
  }

  return (
    <Flex
      flexDirection="column"
      alignItems={'center'}
      justifyContent={'center'}
      width={'100%'}
      position={'relative'}
      padding={isMobile ? '20px' : '50px 160px'}
    >
      <Grid gridTemplateColumns={isMobile ? '1fr' : '1fr 1.5fr'} gridTemplateRows={'1fr'} width={'100%'} gap={isMobile ? '8px' : '60px'}>
        <Flex flexDirection="column" width={'100%'} gap={isMobile ? '32px' : '16px'} alignItems={'center'} padding={'32px'}>
          <img
            ref={onImgRefChange}
            src={therapistDetails?.photo ?? ProfilePhotoPlaceholder}
            alt=""
            width={'100%'}
            height={imgHeight ?? 'auto'}
            style={{ objectFit: 'cover', borderRadius: '50%' }}
          />
          <StatisticsCard>
            <Grid gridTemplateColumns={'1fr'} gridTemplateRows={'1fr'} width={'100%'} gap={'32px'}>
              <Flex flexDirection="column" width={'100%'} alignItems={'center'} justifyContent={'center'}>
                <InlineTextWrapper textAlign={'center'}>
                  <Text variant={'paragraph1'} text={'Cijena vrijedi za jednu sesiju od'} margin="0 4px 0 0" />
                  <Text
                    variant={'paragraph1'}
                    text={` ${therapistDetails?.sessionDuration} minuta `}
                    margin="0 4px 0 0"
                    style={{ fontFamily: 'Barlow-Bold' }}
                  />
                  <Text variant={'paragraph1'} text={'i uključuje proviziju'} whiteSpace={'pre-wrap'} />
                </InlineTextWrapper>
                <Text
                  variant={isMobile ? 'heading4' : 'heading1'}
                  text={calculatePrice()}
                  color={theme.colors.palette.purple}
                  textAlign={'center'}
                />
              </Flex>
              {/* 
              <Flex flexDirection="column" width={'100%'} alignItems={'center'} justifyContent={'center'}>
                <Text
                  variant={isMobile ? 'heading4' : 'heading1'}
                  text={therapistDetails?.clients?.length ?? 0}
                  margin={'0 0 8px 0'}
                  color={theme.colors.palette.purple}
                  textAlign={'center'}
                />
                <Text variant={'paragraph1'} text={'zadovoljnih korisnika'} textAlign={'center'} />
              </Flex> */}
            </Grid>
          </StatisticsCard>
          {therapistDetails?.freeSession && (
            <Button
              variant={'accentDefault'}
              text={t('btn_free_session')}
              minWidth="250px"
              onClick={openFreeSessionModal}
              width={isMobile ? '100%' : 'auto'}
            />
          )}
        </Flex>

        <Flex flexDirection="column" width={'100%'} alignItems={isMobile ? 'center' : 'flex-start'} margin={isMobile ? '' : '32px 0 0 0'}>
          <Text variant={'heading3'} text={therapistDetails?.username} margin={isMobile ? '16px 0 16px 0' : '24px 0 24px 0'} />

          <Text variant={'paragraph1'} text={therapistDetails?.details} whiteSpace={'pre-line'} margin={'0 0 24px 0'} />

          <Flex flexDirection="column" width={'100%'} gap="16px" margin={'24px 0 24px 0'}>
            <Flex flexDirection="row" width={'100%'} gap={'16px'}>
              <Button
                variant={isMobile ? 'primaryDefault' : 'primaryLarge'}
                text={t('btn_book')}
                minWidth={isMobile ? '' : '250px'}
                onClick={onBookSessionClick}
                width={isMobile ? '100%' : 'auto'}
              />

              {isAuthenticated && (
                <Button
                  variant={isMobile ? 'secondaryDefault' : 'secondaryLarge'}
                  text={t('btn_send_message')}
                  minWidth={isMobile ? '' : '250px'}
                  onClick={openChatModal}
                  width={isMobile ? '100%' : 'auto'}
                />
              )}
            </Flex>

            <Button
              variant={isMobile ? 'secondaryDefault' : 'secondaryLarge'}
              text={t('btn_back')}
              width={isMobile ? '100%' : 'auto'}
              minWidth="250px"
              onClick={onBackClick}
            />
          </Flex>
        </Flex>
      </Grid>

      <FreeSessionModal isOpen={showFeeSession} onClose={closeFreeSessionModal} therapist={therapistDetails} />

      {isAuthenticated && <ChatModal isOpen={isChatModalOpen} onClose={closeChatModal} targetUser={therapistDetails} />}
    </Flex>
  );
};

import { httpService } from 'src/config/http';
import { ApiResponse } from 'src/types/ApiResponse';
import { IClientSecretRequestDTO } from './RequestDtos';
import { IClientSecretResponseDTO } from './ResponseDtos';

export default class MonriApi {
  public getClientSecret(data: IClientSecretRequestDTO) {
    return httpService.post<ApiResponse<IClientSecretResponseDTO>>('/monri/secret', data);
  }
}

export const monriAPI = new MonriApi();

import { useForm } from 'react-hook-form';
import { useState } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { RoutesEnum } from 'src/routes';
import { QuestionAnswer } from 'src/types/QuestionAnswer';
import { RolesEnum } from 'src/constants/RolesEnum';
import { useAppDispatch } from 'src/store';
import { register, setRouteAfterAuthorization } from 'src/store/slices/authSlice';
import { useTranslation } from 'react-i18next';

interface RegisterFormProps {
  redirectRoute: RoutesEnum;
}

export interface RegisterFormState {
  name: string;
  email: string;
  city: string;
  country: string;
  password: string;
  repeatPassword: string;
  newsletter: boolean;
}

const formDefaultValues: RegisterFormState = {
  name: '',
  email: '',
  country: '',
  city: '',
  password: '',
  repeatPassword: '',
  newsletter: false,
};

const useRegisterForm = ({ redirectRoute }: RegisterFormProps) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { id } = useParams();
  const location = useLocation();

  const [isSubmittingForm, setIsSubmittingForm] = useState(false);

  const useRegisterFormSchema = yup.object().shape({
    name: yup.string().required(t('error_name_required')),
    email: yup.string().email(t('error_email')).required(t('error_email_required')),
    city: yup.string().required(t('error_city_required')),
    country: yup.string().required(t('error_country_required')),
    password: yup.string().required(t('error_password')).min(6, t('error_password_required')),
    repeatPassword: yup
      .string()
      .required(t('error_password_repeat'))
      .oneOf([yup.ref('password'), null], t('error_password_repeat_match')),
    newsletter: yup.boolean(),
  });

  const registerForm = useForm({
    resolver: yupResolver(useRegisterFormSchema),
    defaultValues: formDefaultValues,
    mode: 'onSubmit',
    reValidateMode: 'onChange',
  });

  const onSubmit = async (data: RegisterFormState, outputAnswers: QuestionAnswer[], role: RolesEnum) => {
    setIsSubmittingForm(true);

    if (location.pathname.includes('/details/')) {
      dispatch(setRouteAfterAuthorization(`/details/${id}`));
    } else if (redirectRoute) {
      dispatch(setRouteAfterAuthorization(redirectRoute));
    }

    await dispatch(
      register({
        username: data.name,
        email: data.email,
        country: data.country,
        city: data.city,
        phone: null,
        password: data.password,
        price: 0,
        details: null,
        role,
        answers: outputAnswers,
        newsletter: data.newsletter,
        sessionDuration: 60,
      })
    );

    setIsSubmittingForm(false);
  };

  return {
    formDefaultValues,
    registerForm,
    isSubmittingForm,
    onFormSubmit: onSubmit,
  };
};

export { useRegisterForm };

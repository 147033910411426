import React, { useContext, useEffect, useState, useMemo } from 'react';
import { RootState, useAppSelector, useAppDispatch } from 'src/store';
import { Button, Flex, Text, Grid, Card } from 'src/components/common';
import { IsMobileContext } from 'src/context/IsMobileContext';
import { useTranslation } from 'react-i18next';
import { getPackages, setSelectedPackage } from 'src/store/slices/packagesSlice';
import { useTheme } from 'styled-components';
import { useDisclosure } from 'src/hooks/useDisclosure';
import { GetMatchedModal } from 'src/pages/home/modals/GetMatchedModal';
import { packagesAPI } from 'src/api/package/ApiRequests';
import { ToastNotifications } from 'src/utils/toast-notifications';
import { parseError } from 'src/utils/error-parser';
import { refreshCurrentUser } from 'src/store/slices/authSlice';
import { PaymentModal } from '../../../components/shared/payment-modal/PaymentModal';
import { getBAM, getBAMwithDiscount, getPackagePrice, getPackagePriceDiscounted } from 'src/utils/price-calculator';
import { format } from 'date-fns';
import { AlertPopup } from 'src/components/shared';

export const ClientPackages = () => {
  const dispatch = useAppDispatch();
  const theme = useTheme();

  const { isMobile } = useContext(IsMobileContext);
  const { t } = useTranslation();

  const { activeTab } = useAppSelector((state: RootState) => state.global);
  const { user } = useAppSelector((state: RootState) => state.auth);
  const { packages, selectedPackage } = useAppSelector((state: RootState) => state.packages);
  const { isOpen: isGetMatchedModalOpen, close: closeGetMatchedModal, open: openGetMatchedModal } = useDisclosure(false);
  const { isOpen: isPayForPackageModalOpen, close: closePayForPackageModal, open: openPayForPackageModal } = useDisclosure(false);

  const [cancelPackageAlert, setCancelPackageAlert] = useState(null);

  const filteredPackages = useMemo(() => {
    return packages?.filter((item) => item.sessions > 1) ?? [];
  }, [packages]);

  const selectedPackagePrice = useMemo(() => {
    if (selectedPackage?.discount > 0) {
      return getBAMwithDiscount(selectedPackage, user.package?.therapist?.price);
    } else {
      return getBAM(selectedPackage, user.package?.therapist?.price);
    }
  }, [user, selectedPackage]);

  useEffect(() => {
    dispatch(getPackages());
  }, [activeTab]);

  const subscribe = async (orderNumber: string) => {
    try {
      const response = await packagesAPI.subscribe({
        packageId: selectedPackage.id,
        orderNumber: orderNumber,
      });
      if (response.success) {
        ToastNotifications.success(response.data, 'subscribe');
        await dispatch(refreshCurrentUser());
        closePayForPackageModal();
      } else {
        ToastNotifications.error(response.error, 'subscribe');
      }
    } catch (error) {
      ToastNotifications.error(parseError(error), 'subscribe');
    }
  };

  const showCancelPackageAlert = () => {
    setCancelPackageAlert(
      <AlertPopup
        title={t('client_packages_5')}
        message={t('client_packages_6')}
        confirmAction={() => unsubscribe()}
        cancelAction={hideCancelPackageAlert}
      />
    );
  };

  const hideCancelPackageAlert = () => {
    setCancelPackageAlert(null);
  };

  const unsubscribe = async () => {
    try {
      const response = await packagesAPI.unsubscribe();
      if (response.success) {
        ToastNotifications.success(response.data, 'unsubscribe');
        await dispatch(refreshCurrentUser());
      } else {
        ToastNotifications.error(response.error, 'unsubscribe');
      }
    } catch (error) {
      ToastNotifications.error(parseError(error), 'unsubscribe');
    }
  };

  return (
    <Flex flexDirection="column" width={'100%'} gap={'16px'} overflow={'scroll'}>
      <Text variant={'heading6'} text={t('client_packages_1')} />

      <Card width={'100%'} borderRadius={'20px'} margin={isMobile ? '0 0 24px 0' : '0 0 48px 0'} padding={'24px'}>
        {user?.package == null ? (
          <Flex
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            width={'100%'}
            height={'75vh'}
            padding={isMobile ? '48px 24px' : '24px'}
            gap={'32px'}
          >
            <Text
              variant={'paragraph1'}
              width={'100%'}
              text={t('client_packages_2')}
              margin={'24px 0'}
              textAlign={'center'}
              whiteSpace={'pre-wrap'}
            />
            <Button
              onClick={openGetMatchedModal}
              text={t('btn_connect_with_therapist')}
              width={isMobile ? '100%' : '50%'}
              margin={'0 0 20px 0'}
            />
          </Flex>
        ) : (
          <Flex flexDirection="column" justifyContent="center" alignItems="center" width={'100%'} height={'75vh'} gap={'32px'}>
            <Text variant={'heading5'} width={'100%'} text={t('client_packages_3')} textAlign={'center'} whiteSpace={'pre-wrap'} />
            <Grid gridTemplateColumns={isMobile ? '1rf' : `repeat(${filteredPackages.length}, 1fr)`} gap={'4px'}>
              {filteredPackages.map((item, index) => (
                <Flex
                  key={index}
                  flexDirection={'column'}
                  border={`2px solid ${selectedPackage == item ? theme.colors.palette.green : theme.colors.border.lighter}`}
                  borderRadius={'16px'}
                  onClick={() => dispatch(setSelectedPackage(item))}
                >
                  <Card width={'100%'} height={'100%'} padding={'0px'}>
                    <Text
                      width={'100%'}
                      variant={'heading7'}
                      text={item.name}
                      textAlign={'center'}
                      padding={'24px'}
                      style={{
                        minHeight: '90px',
                        backgroundColor: `${theme.colors.palette.lightPurple}`,
                        borderTopLeftRadius: '16px',
                        borderTopRightRadius: '16px',
                        overflow: 'hidden',
                      }}
                    />
                    <Flex
                      width={'100%'}
                      height={'100%'}
                      flexDirection={'column'}
                      gap={'16px'}
                      padding={'24px'}
                      alignItems={'center'}
                      justifyContent={'space-between'}
                    >
                      <Flex width={'100%'} flexDirection={'column'} gap={'24px'} justifyContent={'center'}>
                        <Flex width={'100%'} flexDirection={'column'} justifyContent={'center'} alignItems={'center'}>
                          <Text
                            variant={'heading5'}
                            width={'100%'}
                            text={`${getPackagePrice(user, item, user.package?.therapist?.price)}`}
                            textAlign={'center'}
                            textDecoration={item.discount > 0 ? 'line-through' : 'none'}
                          />
                          {item.discount > 0 && (
                            <Card borderRadius={'12px'} backgroundColor={theme.colors.palette.red} padding={'8px 16px'} margin={'8px'}>
                              <Text
                                variant={'heading5'}
                                text={getPackagePriceDiscounted(user, item, user.package?.therapist?.price)}
                                textAlign={'center'}
                                color={theme.colors.palette.white}
                              />
                            </Card>
                          )}
                          <Text
                            variant={'heading5'}
                            width={'100%'}
                            text={`${item.sessions > 1 ? '/ mjesečno' : ''}`}
                            textAlign={'center'}
                          />
                        </Flex>
                      </Flex>

                      <Flex width={'100%'} flexDirection={'column'} gap={'16px'}>
                        {item.id == user?.package?.packageId ? (
                          <Flex
                            width="100%"
                            height="auto"
                            margin={'12px 0 0 0'}
                            alignItems="center"
                            justifyContent="center"
                            flexDirection="column"
                          >
                            <Text
                              variant={'paragraph1'}
                              text={user?.package?.canceledDate ? t('therapist_subscription_6') : t('therapist_subscription_4')}
                              textAlign={'center'}
                              margin={'0 4px 0 0'}
                              color={user?.package?.canceledDate ? theme.colors.palette.red : theme.colors.palette.green}
                            />
                            <Text
                              variant={'heading7'}
                              text={format(new Date(user?.package?.endDate), 'dd/MM/yyyy')}
                              color={user?.package?.canceledDate ? theme.colors.palette.red : theme.colors.palette.green}
                            />
                          </Flex>
                        ) : (
                          <Text variant={'paragraph1'} width={'100%'} text={item.description} textAlign={'center'} />
                        )}

                        <Flex width={'100%'} flexDirection={'row'} gap={'8px'} margin={'24px 0 0 0 '}>
                          <Flex
                            style={{
                              minWidth: '20px',
                              minHeight: '20px',
                              borderRadius: '50%',
                              backgroundColor: theme.colors.palette.green,
                            }}
                          />
                          <Text variant={'paragraph1'} text={`${item.sessions} online sesije mjesečno`} />
                        </Flex>
                        <Flex width={'100%'} flexDirection={'row'} gap={'8px'}>
                          <Flex
                            style={{
                              minWidth: '20px',
                              minHeight: '20px',
                              borderRadius: '50%',
                              backgroundColor: theme.colors.palette.green,
                            }}
                          />
                          <Text variant={'paragraph1'} text={t('therapist_subscription_7')} />
                        </Flex>
                      </Flex>
                      {item.id == user?.package?.packageId && !user?.package?.canceledDate ? (
                        <Button
                          variant={item.id == selectedPackage?.id ? 'redDefault' : 'secondaryRedDefault'}
                          margin={'24px 0 0 0'}
                          text={'Otkaži pretplatu'}
                          onClick={showCancelPackageAlert}
                        />
                      ) : (
                        <Button
                          variant={item.id == selectedPackage?.id ? 'accentDefault' : 'secondaryAccentDefault'}
                          margin={'24px 0 0 0'}
                          text={
                            user?.package?.canceledDate
                              ? t('btn_subscribe')
                              : item.sessions > 1
                              ? t('btn_change_packages')
                              : t('btn_buy_one_session')
                          }
                          onClick={openPayForPackageModal}
                        />
                      )}
                    </Flex>
                  </Card>
                </Flex>
              ))}
            </Grid>
          </Flex>
        )}
      </Card>

      <GetMatchedModal isOpen={isGetMatchedModalOpen} onClose={closeGetMatchedModal} />

      <PaymentModal
        isOpen={isPayForPackageModalOpen}
        amount={selectedPackagePrice}
        orderNumber={`PSHT-${new Date().getTime()}`}
        enableVoucher={selectedPackage?.sessions == 1}
        onClose={closePayForPackageModal}
        paymentSuccessful={subscribe}
        payWithVoucher={() => {}}
      />

      {cancelPackageAlert}
    </Flex>
  );
};

import React, { useContext } from 'react';
import styled from 'styled-components';
import DatePicker, { registerLocale, setDefaultLocale, ReactDatePickerProps } from 'react-datepicker';
import { Flex, SelectDropdown, Button } from 'src/components/common';
import { getMonth, getYear, setHours, setMinutes } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { IsMobileContext } from 'src/context/IsMobileContext';
import hr from 'date-fns/locale/hr';
import range from 'lodash/range';

registerLocale('hr', hr);
setDefaultLocale('hr');

const DatePickerWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;

  > div {
    width: 100%;
    display: flex;
    justify-content: center;

    .react-datepicker {
      width: 100%;
      display: flex;
      border: none;

      .react-datepicker__triangle {
        display: none;
      }

      .react-datepicker__week {
        display: flex;
      }

      .react-datepicker__month-container {
        width: ${(props) => props.monthTrackWidth};

        .react-datepicker__day,
        .react-datepicker__day-name {
          width: calc(100% / 7) !important;
          height: ${(props) => props.dayWrapperSize};
          display: inline-flex;
          justify-content: center;
          align-items: center;
          font-size: ${(props) => props.dayTextSize}px;
          border-radius: 10px;
        }

        .react-datepicker__day {
          &--today {
            font-size: ${(props) => props.dayTextSize + (props.isMobile ? 2 : 6)}px;
            font-weight: 700;
            color: ${(props) => props.theme.colors.palette.purple};
            background-color: ${(props) => props.theme.colors.palette.green} !important;
            border-radius: 10px;
          }

          &--selected {
            font-size: ${(props) => props.dayTextSize + (props.isMobile ? 1 : 6)}px;
            font-weight: bold !important;
            color: ${(props) => props.theme.colors.palette.white};
            border-radius: 10px;
          }

          &--disabled {
            background-color: transparent !important;
            opacity: 0.5;

            &.react-datepicker__day--today { 
              font-size: ${(props) => props.dayTextSize + (props.isMobile ? 2 : 6)}px;
              font-weight: 700;
              color: ${(props) => props.theme.colors.palette.white};
              background-color: ${(props) => props.theme.colors.palette.green} !important;
              border-radius: 10px;
            }
          }
        }
      }

      .react-datepicker__time-list-item {
        display: none;
        width: 100% !important;
        height: 45px !important;
        display: flex;
        justify-content: center !important;
        align-items: center !important;
        font-size: ${(props) => props.dayTextSize}px;

        &--disabled {
          display: none;
        }

        &--selected {
          background-color: ${(props) => props.theme.colors.palette.purple} !important;
        }

        @media (max-width: ${(props) => props.theme.breakpoints.md}) {
          font-size: ${(props) => props.dayTextSize}px;
          height: 30px !important;
        }
      }

      .react-datepicker__day--keyboard-selected {
        background-color: transparent !important;
      }

      .basic-single .select__control {
        width: 100%;
        min-height: 40px;
        display: flex;
        justify-content: center;
        background-color: rgb(250, 250, 250);
        border: none;
        border-size 0px;
        border-radius: 20px;
        align-items: center;
        font-size: ${(props) => props.dayTextSize - 2}px;
      }    
    }
  }

  .react-datepicker__day--highlighted.react-datepicker__day--selected {
    outline: 3px solid ${(props) => props.theme.colors.palette.green} !important;
    outline-offset: 1px;
  }

  .react-datepicker__day--highlighted,
  .react-datepicker__month-text--highlighted,
  .react-datepicker__quarter-text--highlighted,
  .react-datepicker__year-text--highlighted {
    border-radius: 0.3rem;
    background-color: ${(props) => props.theme.colors.palette.purple};
    color: #fff !important;
  }

  .react-datepicker__day--highlighted-available-to-book {
    border-radius: 0.3rem;
    background-color: ${(props) => props.theme.colors.palette.green} !important;
    color: #fff !important;
  }

  .react-datepicker__day--highlighted-booked {
    border-radius: 0.3rem;
    background-color: ${(props) => props.theme.colors.palette.red} !important;
    color: #fff !important;
    border-radius: 10px;
  }

   .react-datepicker__day--highlighted-availability-booked-match {
    border-radius: 0.3rem;
    background-image: ${(props) =>
      `linear-gradient(135deg, ${props.theme.colors.palette.green} 50%, ${props.theme.colors.palette.red} 50%)`} !important;
    color: #fff !important;
  }

  .react-datepicker-time__header {
    height: auto;
    font-size: ${(props) => props.dayTextSize};
    margin-left: 1px;
    
    @media (max-width: ${(props) => props.theme.breakpoints.md}) {
      font-size: ${(props) => props.dayTextSize - 7}px;
    }
  }

  .react-datepicker__time-container
    .react-datepicker__time
    .react-datepicker__time-box
    ul.react-datepicker__time-list
    li.react-datepicker__time-list-item {
    width: 100%;
    height: 60px;
    padding: 5px 10px;
    white-space: nowrap;
  }

  .react-datepicker__time-container {
    display: ${(props) => (props.timeTrackVisible ? '' : 'none')};
    width: ${(props) => props.timeTrackWidth};
    border-left: 1px solid ${(props) => props.theme.colors.border.lighter};
  }

  .react-datepicker__time {
    .react-datepicker__time-box {
      width: 100%;
      margin-left: 1px;
    }
  }

  .react-datepicker__day-names {
    display: flex;
    align-items: center;
    height: 5rem !important;
  }

  .select__value-container {
    padding: 20px 12px;
  }

  .react-datepicker__header {
    font-size: ${(props) => props.dayTextSize};
    background-color: transparent;
    border: none;
  }

  .react-datepicker__day--selected {
    background-color: ${(props) => props.theme.colors.palette.purple};
  }

  .react-datepicker__header--custom {
    button {
      width: 40px;
      height: 40px;
      background-color: ${(props) => props.theme.colors.background.secondary.BG1};
      margin-left: ${(props) => props.monthSpacer};
      margin-right: ${(props) => props.monthSpacer};
      display: flex !important;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      border: 1px solid ${(props) => props.theme.colors.border.lighter} !important;

      &:disabled {
        font-size: ${(props) => props.dayTextSize};
      }

      > div {
        font-size: 22px;
        font-family: 'Barlow-Light' !important;
        line-height: 22px;
        margin-bottom: 4px;
        display: flex !important;
        align-items: center;
        justify-content: center;
      }

      @media (max-width: ${(props) => props.theme.breakpoints.md}) {
        width: 30px;
        height: 30px;
      }
    }
  }

  .select__indicator {
    svg {
      width: 16px;
      height: 16px;
    }
  }
`;

export const DateTimePicker = ({
  selectedDate,
  setSelectedDate,
  minTime = setHours(setMinutes(new Date(), 0), 0),
  maxTime = setHours(setMinutes(new Date(), 0), 23),
  timeIntervalInMinutes = 60,
  maxYears = getYear(new Date()) + 10,
  maxMonthsFromNow = 2,
  dayWrapperSize = '2.7rem',
  dayTextSize = '18px',
  monthWidth = '100px',
  monthSpacer = '8px',
  timeTrackVisible = true,
  timeTrackWidth = "20%'",
  monthTrackWidth = '80%',
  ...rest
}: ReactDatePickerProps) => {
  const { t } = useTranslation();
  const { isMobile } = useContext(IsMobileContext);

  const DATE_FORMAT = 'dd MMMM, yyyy HH:mm';
  const TIME_FORMAT = 'HH:mm';
  const DEFAULT_MONTHS = [
    t('date_time_2'),
    t('date_time_3'),
    t('date_time_4'),
    t('date_time_5'),
    t('date_time_6'),
    t('date_time_7'),
    t('date_time_8'),
    t('date_time_9'),
    t('date_time_10'),
    t('date_time_11'),
    t('date_time_12'),
    t('date_time_13'),
  ];

  const years = range(getYear(new Date()), maxYears, 1);
  const months = DEFAULT_MONTHS;
  const maxDate = new Date(new Date().getFullYear(), new Date().getMonth() + maxMonthsFromNow, 0);

  const yearsOptions = years.map((value) => {
    return {
      label: value,
      value,
    };
  });

  const monthsOptions = months.map((value) => {
    return {
      label: value,
      value,
    };
  });

  return (
    <DatePickerWrapper
      isMobile={isMobile}
      dayWrapperSize={dayWrapperSize}
      dayTextSize={dayTextSize}
      monthWidth={monthWidth}
      monthSpacer={monthSpacer}
      timeTrackWidth={timeTrackWidth}
      timeTrackVisible={timeTrackVisible}
      monthTrackWidth={monthTrackWidth}
    >
      <DatePicker
        {...rest}
        dateFormat={DATE_FORMAT}
        timeCaption={t('date_time_1')}
        timeFormat={TIME_FORMAT}
        locale="hr"
        showTimeSelect
        inline
        selected={selectedDate}
        minDate={new Date()}
        maxDate={maxDate}
        minTime={minTime}
        maxTime={maxTime}
        timeIntervals={timeIntervalInMinutes}
        onChange={setSelectedDate}
        renderCustomHeader={({
          date,
          changeYear,
          changeMonth,
          decreaseMonth,
          increaseMonth,
          prevMonthButtonDisabled,
          nextMonthButtonDisabled,
        }) => (
          <Flex width={'100%'} flexDirection={'row'} gap={isMobile ? '0px' : '10px'} alignItems={'center'}>
            <Button disabled={prevMonthButtonDisabled} variant="transparentSmall" onClick={decreaseMonth} text="<" noBorder={true} />
            <SelectDropdown
              width="auto"
              variant="default"
              options={yearsOptions}
              value={getYear(date)}
              onChange={(value) => changeYear(value)}
            />
            <SelectDropdown
              width="auto"
              variant="default"
              options={monthsOptions}
              value={months[getMonth(date)]}
              onChange={(value) => changeMonth(months.indexOf(value))}
            />
            <Button disabled={nextMonthButtonDisabled} variant="transparentSmall" onClick={increaseMonth} text=">" noBorder={true} />
          </Flex>
        )}
      />
    </DatePickerWrapper>
  );
};

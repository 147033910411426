import { httpService } from 'src/config/http';
import { ApiResponse } from 'src/types/ApiResponse';
import { CompanyVoucherRequestDTO } from './RequestDtos';
import { CompanyVoucher } from 'src/types/CompanyVoucher';

export default class CompaniesAPI {
  public validateVoucher(data: CompanyVoucherRequestDTO) {
    return httpService.post<ApiResponse<CompanyVoucher>>('/companies/vouchers/validate', data);
  }
}

export const companiesAPI = new CompaniesAPI();

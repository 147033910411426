import React, { useContext, useMemo, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { RootState, useAppSelector } from 'src/store';
import { Text, Button, Card, Flex } from 'src/components/common';
import { DateTimePicker } from 'src/components/shared';
import { RolesEnum } from 'src/constants/RolesEnum';
import { UseVoucherModal } from './modals/UseVoucherModal';
import { User } from 'src/types/User';
import { IsMobileContext } from 'src/context/IsMobileContext';
import { useTranslation } from 'react-i18next';
import { useDisclosure } from 'src/hooks/useDisclosure';
import { config } from 'src/config/config';
import { format } from 'date-fns';
import { Availability } from 'src/types/Availability';
import { CompanyVoucher } from 'src/types/CompanyVoucher';

export interface BookSessionDateStepProps {
  loading: boolean;
  hasPackage: boolean;
  therapist: User;
  selectedAvailability: Availability;
  setSelectedAvailability: (availability: Availability) => void;
  voucher: CompanyVoucher;
  setVoucher: (voucher: CompanyVoucher) => void;
  nextClicked: () => void;
  bookFromPackage: () => void;
}

export const BookSessionDateStep = ({
  loading,
  hasPackage,
  therapist,
  selectedAvailability,
  setSelectedAvailability,
  voucher,
  setVoucher,
  nextClicked,
  bookFromPackage,
}: BookSessionDateStepProps) => {
  const navigate = useNavigate();

  const { t } = useTranslation();
  const { isMobile } = useContext(IsMobileContext);
  const { user } = useAppSelector((state: RootState) => state.auth);
  const { isOpen, close, open } = useDisclosure(false);

  const [selectedDate, setSelectedDate] = useState(new Date());

  const minTimeBeforeBooking = useMemo(() => {
    // For clients booking appointment should be available only 48hrs before session
    let minTimeBeforeBooking = config.MIN_TIME_BEFORE_BOOKING_48;
    // Therapists are able to book appointment without any time related rules
    if (user?.role === RolesEnum.THERAPIST) {
      minTimeBeforeBooking = 0;
    } else if (therapist?.earlyBooking == true) {
      // If user is booking then it depends on therapist settings
      minTimeBeforeBooking = config.MIN_TIME_BEFORE_BOOKING_24;
    }
    return new Date().getTime() + minTimeBeforeBooking;
  }, [user, therapist]);

  const availableDates = useMemo(() => {
    return (
      therapist?.availabilities
        ?.map((a) => new Date(a.date))
        ?.filter((date) => date.getTime() > minTimeBeforeBooking)
        ?.sort((a, b) => a.getTime() - b.getTime()) ?? []
    );
  }, [therapist]);

  const availableTimes = useMemo(() => {
    return availableDates.filter((date) => format(selectedDate, 'yyyy/MM/dd') === format(date, 'yyyy/MM/dd'));
  }, [selectedDate]);

  const isNextAvailable = useMemo(() => {
    const selectedDateHour = selectedDate?.getHours();
    const selectedDateAvailableHours = availableTimes?.map((t) => t.getHours());
    return selectedDateAvailableHours.includes(selectedDateHour);
  }, [selectedDate]);

  const setSelectedDateTime = (date) => {
    const selectedDateHour = date.getHours();
    const selectedDateAvailableHours = availableTimes?.map((t) => t.getHours());
    if (selectedDateAvailableHours.includes(selectedDateHour)) {
      setSelectedDate(date);
    } else {
      const dateTime = availableDates.filter((item) => format(date, 'yyyy/MM/dd') === format(item, 'yyyy/MM/dd'));
      setSelectedDate(dateTime && dateTime.length > 0 ? dateTime[0] : null);
    }
  };

  useEffect(() => {
    const availability = therapist?.availabilities?.find(
      (item) => format(selectedDate, 'yyyy/MM/dd HH:mm') === format(item.date, 'yyyy/MM/dd HH:mm')
    );
    setSelectedAvailability(availability);
  }, [selectedDate, therapist]);

  const payOrNext = () => {
    if (hasPackage) {
      bookFromPackage();
    } else {
      nextClicked();
    }
  };

  const getDiscount = () => {
    return voucher != null ? `uz ${voucher.discount}% popusta` : '';
  };

  return (
    <Flex width={'100%'} height={hasPackage ? '85vh' : isMobile ? 'auto' : '75vh'} flexDirection={'column'} overflow={'scroll'}>
      <Card width={'100%'} height={'100%'} borderRadius={'20px'} padding={isMobile ? '16px' : '24px'} justifyContent={'space-between'}>
        <Text
          width={'100%'}
          textAlign={'center'}
          variant={'heading7'}
          text={`${t('book_therapist_1')} sa ${therapist?.username}`}
          padding={'24px 0'}
        />
        <DateTimePicker
          selectedDate={selectedDate}
          setSelectedDate={setSelectedDateTime}
          includeDates={availableDates}
          highlightDates={availableDates}
          includeTimes={availableTimes}
          timeIntervalInMinutes={config.CALENDAR_TIME_INTERVAL_IN_MINUTES}
          minTime={config.CALENDAR_MIN_TIME}
          maxTime={config.CALENDAR_MAX_TIME}
          maxYears={config.CALENDAR_MAX_YEARS}
          excludeOutOfBoundsTimes={true}
          dayWrapperSize={isMobile ? '2rem' : '4rem'}
          dayTextSize={isMobile ? 14 : 18}
          timeTrackWidth={isMobile ? '15%' : '20%'}
          monthTrackWidth={isMobile ? '85%' : '80%'}
          monthYearHeaderMargin={isMobile ? 0 : 32}
        />
        <Flex width={'100%'} flexDirection={'row'} justifyContent={'space-between'} alignItems={'stretch'} padding={'24px 0 0 0'}>
          <Button variant="secondaryDefault" text={t('btn_back')} onClick={() => navigate(-1)} />
          <Flex flexDirection={'row'} gap={'8px'}>
            {voucher == null && <Button variant="accentDefault" text={t('btn_have_voucher')} disabled={!isNextAvailable} onClick={open} />}

            <Button
              variant="primaryDefault"
              text={hasPackage ? t('btn_book') : `${t('btn_forward')} ${getDiscount()}`}
              isLoading={loading}
              disabled={loading || !isNextAvailable}
              onClick={payOrNext}
            />
          </Flex>
        </Flex>
        <UseVoucherModal
          therapist={therapist}
          availability={selectedAvailability}
          isOpen={isOpen}
          setVoucher={setVoucher}
          onClose={close}
        />
      </Card>
    </Flex>
  );
};

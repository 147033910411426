import React, { useMemo, useState, forwardRef } from 'react';
import { useTheme } from 'styled-components';

import { Text } from 'src/components/common';
import { PasswordShow, PasswordHide } from 'src/components/icons/ui-icons';
import { InputProps } from 'src/types/Input';
import {
  StyledInputContainer,
  InputWrapper,
  StyledInput,
  RightElementWrapper,
  LeftElementWrapper,
  ErrorMessageWrapper,
  getColorStyles,
  getFontStyles,
} from './styles';

export const Input = forwardRef((props: InputProps, ref: React.ForwardedRef<HTMLInputElement>) => {
  const theme = useTheme();

  const {
    label,
    helperText,
    leftElement,
    rightElement,
    placeholder = '',
    disabled = false,
    errorMessage,
    patternMessage = 'Vrijednost nije validna',
    invalid = false,
    autoFocus = false,
    readOnly,
    type = 'text',
    style = {},
    pattern,
    variant = 'primary',
    minChars = undefined,
    onChange,
    ...rest
  } = props;
  const [passwordShown, setPasswordShown] = useState(false);

  const hasPatternError = useMemo(() => {
    if (pattern) {
      const regex = new RegExp(`^(${pattern})$`);

      return props.value !== '' && !regex.test(props.value.toString());
    } else {
      return false;
    }
  }, [pattern, props.value]);

  const togglePasswordVisiblity = () => {
    setPasswordShown(!passwordShown);
  };

  const colorStyles = useMemo(() => {
    const isInvalid = !!errorMessage || invalid || !!hasPatternError;

    return getColorStyles(isInvalid, readOnly, theme);
  }, [errorMessage, hasPatternError, readOnly, theme, invalid]);

  const fontStyles = useMemo(() => {
    return getFontStyles(theme);
  }, [theme]);

  const minCharsError = useMemo(() => {
    return minChars && type === 'text' && rest.value && rest.value.toString().length < minChars;
  }, [minChars, rest.value, type]);

  const passwordToggleIcon = useMemo(() => {
    return passwordShown ? (
      <RightElementWrapper onClick={togglePasswordVisiblity}>
        <PasswordHide />
      </RightElementWrapper>
    ) : (
      <RightElementWrapper onClick={togglePasswordVisiblity}>
        <PasswordShow />
      </RightElementWrapper>
    );
  }, [passwordShown]);

  const onChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChange && onChange(e);
  };

  return (
    <StyledInputContainer {...rest}>
      {label && <Text text={label} variant="paragraph3" margin="0 0 4px 8px" />}
      <InputWrapper>
        <StyledInput
          {...rest}
          style={style}
          ref={ref}
          type={passwordShown ? 'text' : type}
          placeholder={type === 'password' ? (!placeholder ? '* * * * * *' : placeholder) : placeholder}
          leftElement={leftElement}
          rightElement={rightElement}
          disabled={disabled}
          colorStyles={colorStyles}
          fontStyles={fontStyles}
          autoFocus={autoFocus}
          variant={variant}
          onChange={onChangeHandler}
        />

        {leftElement ? <LeftElementWrapper>{leftElement}</LeftElementWrapper> : null}
        {type === 'password' ? passwordToggleIcon : rightElement ? <RightElementWrapper> {rightElement}</RightElementWrapper> : null}
      </InputWrapper>

      {helperText && !errorMessage && !pattern && !hasPatternError && !minCharsError && (
        <Text text={helperText} variant="paragraph3" margin="4px 0 0 8px" color={theme.colors.text.tertiary} />
      )}
      {errorMessage && !pattern && !hasPatternError && !minCharsError && (
        <ErrorMessageWrapper>
          <Text text={errorMessage} variant="paragraph3" margin="4px 0 0 8px" color={theme.colors.palette.red} />
        </ErrorMessageWrapper>
      )}

      {minCharsError && !errorMessage && !pattern && !hasPatternError && (
        <ErrorMessageWrapper>
          <Text text={`Minimalan broj znakova je ${minChars}`} variant="paragraph3" margin="4px 0 0 8px" color={theme.colors.palette.red} />
        </ErrorMessageWrapper>
      )}

      {pattern && hasPatternError && !minCharsError && !errorMessage && (
        <ErrorMessageWrapper>
          <Text text={patternMessage} variant="paragraph3" color={theme.colors.palette.red} />
        </ErrorMessageWrapper>
      )}
    </StyledInputContainer>
  );
});

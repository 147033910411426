import { User } from 'src/types/User';
import { Package } from 'src/types/Package';
import { CompanyVoucher } from 'src/types/CompanyVoucher';

export function getPackagePrice(user: User, item: Package, therapistPrice: number) {
  const bam = `${getBAM(item, therapistPrice)} BAM`;
  const eur = `${getEUR(item, therapistPrice)} EUR`;
  const international = user?.country != 'Bosna i Hercegovina';
  return international ? eur : bam;
}

export function getPackagePriceDiscounted(user: User, item: Package, therapistPrice: number) {
  const bam = `${getBAMwithDiscount(item, therapistPrice)} BAM`;
  const eur = `${getEURWithDiscount(item, therapistPrice)} EUR`;
  const international = user?.country != 'Bosna i Hercegovina';
  return international ? eur : bam;
}

export function getPackagePriceDiscountedWithVoucher(user: User, item: Package, therapistPrice: number, voucher: CompanyVoucher) {
  const packageBam = getBAMwithDiscount(item, therapistPrice);
  const packageEur = getEURWithDiscount(item, therapistPrice);
  const discount = Number(voucher.discount ?? 0) / 100;
  const bamDiscounted = packageBam * (1 - discount);
  const eutDiscounted = packageEur * (1 - discount);
  const bam = `${bamDiscounted.toFixed(2)} BAM`;
  const eur = `${eutDiscounted.toFixed(2)} EUR`;
  const international = user?.country != 'Bosna i Hercegovina';
  return international ? eur : bam;
}

export function getBAM(item: Package, therapistPrice?: number) {
  if (!item) return 0;

  const fee = Number(process.env.REACT_APP_PRICE_FEE ?? 0);
  const bam = (Number(therapistPrice ?? 0) + fee) * item.sessions;
  const roundBAM = Number.isInteger(bam) ? bam : bam.toFixed(2);
  return Number(roundBAM);
}

export function getBAMwithDiscount(item: Package, therapistPrice?: number) {
  if (!item) return 0;

  const fee = Number(process.env.REACT_APP_PRICE_FEE ?? 0);
  const bulkPrice = (Number(therapistPrice ?? 0) + fee) * item.sessions;
  const discount = Number(item.discount ?? 0) / 100;
  const bam = bulkPrice * (1 - discount);
  const roundBAM = Number.isInteger(bam) ? bam : bam.toFixed(2);
  return Number(roundBAM);
}

export function getBAMwithVoucherDiscount(item: Package, voucher: CompanyVoucher, therapistPrice?: number) {
  if (!item) return 0;

  const fee = Number(process.env.REACT_APP_PRICE_FEE ?? 0);
  const bulkPrice = (Number(therapistPrice ?? 0) + fee) * item.sessions;
  const discount = Number(voucher.discount ?? 0) / 100;
  const bam = bulkPrice * (1 - discount);
  const roundBAM = Number.isInteger(bam) ? bam : bam.toFixed(2);
  return Number(roundBAM);
}

export function getEUR(item: Package, therapistPrice?: number) {
  if (!item) return 0;

  const bam = getBAM(item, therapistPrice);
  const eur = bam / 1.95583;
  const roundEUR = Number.isInteger(eur) ? eur : eur.toFixed(2);
  return Number(roundEUR);
}

export function getEURWithDiscount(item: Package, therapistPrice?: number) {
  if (!item) return 0;

  const fee = Number(process.env.REACT_APP_PRICE_FEE ?? 0);
  const bulkPrice = (Number(therapistPrice ?? 0) + fee) * item.sessions;
  const discount = Number(item.discount ?? 0) / 100;
  const bam = bulkPrice * (1 - discount);
  const eur = bam / 1.95583;
  const roundEUR = Number.isInteger(eur) ? eur : eur.toFixed(2);
  return Number(roundEUR);
}

export function getEURwithVoucherDiscount(item: Package, voucher: CompanyVoucher, therapistPrice?: number) {
  if (!item) return 0;

  const fee = Number(process.env.REACT_APP_PRICE_FEE ?? 0);
  const bulkPrice = (Number(therapistPrice ?? 0) + fee) * item.sessions;
  const discount = Number(voucher.discount ?? 0) / 100;
  const bam = bulkPrice * (1 - discount);
  const eur = bam / 1.95583;
  const roundEUR = Number.isInteger(eur) ? eur : eur.toFixed(2);
  return Number(roundEUR);
}

export function getTherapistPriceBAM(therapistPrice?: number) {
  if (!therapistPrice) return 0;

  const fee = Number(process.env.REACT_APP_PRICE_FEE ?? 0);
  const bam = Number(therapistPrice ?? 0) + fee;
  const roundBAM = Number.isInteger(bam) ? bam : bam.toFixed(2);
  return Number(roundBAM);
}

import React, { useMemo } from 'react';
import { Flex } from 'src/components/common';
import { RolesEnum } from 'src/constants/RolesEnum';
import { RootState, useAppSelector } from 'src/store';
import { ClientDashboard } from 'src/pages/dashboard/client/ClientDashboard';
import { TherapistDashboard } from 'src/pages/dashboard/therapist/TherapistDashboard';

export const Dashboard = () => {
  const { isAuthenticated, user } = useAppSelector((state: RootState) => state.auth);

  const PageContent = useMemo(() => {
    if (isAuthenticated && user) {
      return user?.role === RolesEnum.THERAPIST ? <TherapistDashboard /> : <ClientDashboard />;
    }
  }, [isAuthenticated, user]);

  return (
    <Flex flexDirection="column" flex="1">
      {PageContent}
    </Flex>
  );
};

import { httpService } from 'src/config/http';
import { Appointment } from 'src/types/Appointment';
import { ApiResponse } from 'src/types/ApiResponse';
import {
  BookUnpaidAppointmentRequestDTO,
  UpdateAppointmentRequestDTO,
  PayAppointmentRequestDTO,
  RequestFreeSessionDTO,
  BookAppoinmentRequestDTO,
  BookPaidAppoinmentRequestDTO,
  BookFromPackageAppoinmentRequestDTO,
  BookUnpaidAppointmentTherapistRequestDTO,
  BookUnpaidAppointmentClientRequestDTO
} from './RequestDtos';

export default class AppointmentsAPI {
  public bookAppointment(data: BookAppoinmentRequestDTO) {
    return httpService.post<ApiResponse<any>>('/appointments/book', data);
  }

  public bookPaidAppointment(data: BookPaidAppoinmentRequestDTO) {
    return httpService.post<ApiResponse<any>>('/appointments/book/paid', data);
  }

  public bookFromPackageAppointment(data: BookFromPackageAppoinmentRequestDTO) {
    return httpService.post<ApiResponse<any>>('/appointments/book/package', data);
  }

  public bookUnpaidAppointmentTherapist(data: BookUnpaidAppointmentTherapistRequestDTO) {
    return httpService.post<ApiResponse<any>>('/appointments/book/unpaid/therapist', data);
  }

  public bookUnpaidAppointmentClient(data: BookUnpaidAppointmentClientRequestDTO) {
    return httpService.post<ApiResponse<any>>('/appointments/book/unpaid/client', data);
  }

  public bookAppointmentWithVoucher(data: BookUnpaidAppointmentRequestDTO) {
    return httpService.post<ApiResponse<any>>('/appointments/book/voucher', data);
  }
  
  public payAppointmentWithVoucher(data: PayAppointmentRequestDTO) {
    return httpService.post<ApiResponse<any>>('/appointments/voucher/pay', data);
  }

  public cencelAppointment(appointmentId: number) {
    return httpService.post<ApiResponse<any>>(`/appointments/cancel/${appointmentId}`);
  }

  public updateAppointment(appointmentId: string, data: UpdateAppointmentRequestDTO) {
    return httpService.patch<Appointment>(`/appointments/${appointmentId}`, data);
  }

  public deleteAppointment(appointmentId: string) {
    return httpService.delete(`/appointments/${appointmentId}`);
  }

  public requestFreeSession(data: RequestFreeSessionDTO) {
    return httpService.post<ApiResponse<string>>(`/free-session`, data);
  }
}

export const appointmentsAPI = new AppointmentsAPI();

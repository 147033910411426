import React, { useEffect, useState, useContext, useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import styled, { useTheme } from 'styled-components';

import { Button, Flex, SelectDropdown, Textarea, Text } from 'src/components/common';
import RegisterForm from 'src/components/shared/register-form/RegisterForm';

import { questionnaireAPI } from 'src/api/questionnaire/ApiRequests';
import { IsMobileContext } from 'src/context/IsMobileContext';
import { Question } from 'src/types/Question';
import { Answer } from 'src/types/Answer';
import { QuestionChoiceTypeEnum } from 'src/constants/QuestionChoiceTypeEnum';
import { RootState, useAppSelector } from 'src/store';
import { parseError } from 'src/utils/error-parser';
import { ToastNotifications } from 'src/utils/toast-notifications';
import { RolesEnum } from 'src/constants/RolesEnum';
import { RoutesEnum } from 'src/routes';
import { delay } from 'src/utils/delay';
import { QuestionAnswer } from 'src/types/QuestionAnswer';
import { useTranslation } from 'react-i18next';
import LoginForm from '../login-modal/form/login/LoginForm';

const QuestionnaireWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: ${(props) => props.height};
  background-color: ${(props) => props.theme.colors.palette.white};
  align-items: ${(props) => props.horizontalAlignment || 'center'};
  justify-content: center;
  border-radius: 16px;
  padding: 24px;

  @media (max-width: ${(props) => props.theme.breakpoints.lg}) {
    padding: 16px;
  }
`;

const SingleOrMultipleChoiceItemsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 12px;
`;

const SingleOrMultipleChoiceItem = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  cursor: pointer !important;
  padding: 12px;
  background-color: ${(props) => (props.selected ? props.theme.colors.palette.darkPurple : props.theme.colors.palette.purple)};
  border-top-left-radius: ${(props) => (props.isFirst ? '16px' : '0px')};
  border-top-right-radius: ${(props) => (props.isFirst ? '16px' : '0px')};
  border-bottom-left-radius: ${(props) => (props.isLast ? '16px' : '0px')};
  border-bottom-right-radius: ${(props) => (props.isLast ? '16px' : '0px')};

  * {
    cursor: pointer !important;
  }
`;

const NavigationWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  gap: 20px;
  margin: 64px 0 0 0;
`;

interface QuestionnaireProps {
  role: RolesEnum;
  questions: Question[];
  height?: string;
}

export const Questionnaire = ({ role, questions, height = 'auto' }: QuestionnaireProps) => {
  const navigate = useNavigate();
  const theme = useTheme();
  const { isMobile } = useContext(IsMobileContext);
  const { t } = useTranslation();

  const { isAuthenticated } = useAppSelector((state: RootState) => state.auth);

  const unansweredQuestions = JSON.parse(JSON.stringify(questions));
  const [submitted, setSubmitted] = useState(false);
  const [selectedQuestionIndex, setSelectedQuestionIndex] = useState(0);
  const [questionsWithAnswers, setQuestionsWithAnswers] = useState(
    unansweredQuestions.map((q) => {
      return { ...q, answered: '' };
    })
  );

  const outputAnswers = useMemo(() => {
    const result: QuestionAnswer[] = [];
    questionsWithAnswers.forEach((question: Question) => {
      if (question.answer_type.type === QuestionChoiceTypeEnum.TEXT) {
        result.push({
          questionId: question.id,
          answerText: question.answered,
          answerIds: null,
        });
      } else {
        result.push({
          questionId: question.id,
          answerText: null,
          answerIds: question.answered as number[],
        });
      }
    });
    return result;
  }, [questionsWithAnswers]);

  const [showLogin, setShowLogin] = useState(false);

  useEffect(() => {
    return () => {
      setQuestionsWithAnswers(questions);
    };
  }, []);

  const goBack = useCallback(() => {
    if (selectedQuestionIndex === 0) {
      return;
    }

    setSelectedQuestionIndex(selectedQuestionIndex - 1);
  }, [setSelectedQuestionIndex, selectedQuestionIndex]);

  const goForward = useCallback(async () => {
    if (selectedQuestionIndex === questions.length - 1) {
      if (isAuthenticated) {
        await delay(500);
        await submitQuestionnaire();
      } else {
        setSubmitted(true);
      }
    } else {
      setSelectedQuestionIndex(selectedQuestionIndex + 1);
    }
  }, [isAuthenticated, selectedQuestionIndex, questions, outputAnswers]);

  const handleAnswering = (value: string | number) => {
    const updatedQuestions = questionsWithAnswers.map((q) => {
      if (q.id === questionsWithAnswers[selectedQuestionIndex].id) {
        return {
          ...q,
          answered: value,
        };
      }
      return q;
    });
    setQuestionsWithAnswers(updatedQuestions);
  };

  const getDropdownOptions = (answers: Answer[]) => {
    return answers.map((a) => {
      return {
        label: a.answer.toString(),
        value: a.id,
      };
    });
  };

  const submitQuestionnaire = async () => {
    try {
      const response: any = await questionnaireAPI.storeAnswersForMultipleQuestions({ answers: outputAnswers });

      if (response.success) {
        navigate(RoutesEnum.SUGGESTED_THERAPISTS);
      }
    } catch (error) {
      const err = parseError(error);
      ToastNotifications.error(err, 'submitQuestionnaire');
    }
  };

  return (
    <QuestionnaireWrapper height={height}>
      {questions.length === 0 && (
        <Text variant={'heading5'} width={'75%'} text={t('questionaire_1')} margin={'0 0 32px 0'} textAlign="center" />
      )}

      {!submitted && questions.length > 0 && (
        <>
          <Text
            variant={'heading6'}
            width={'100%'}
            text={questions[selectedQuestionIndex].question}
            margin={'0 0 32px 0'}
            textAlign="center"
          />

          {questions[selectedQuestionIndex].answer_type.type === QuestionChoiceTypeEnum.TEXT && (
            <Textarea
              value={questionsWithAnswers[selectedQuestionIndex].answered}
              placeholder={t('hint_answer')}
              minChars={
                questions[selectedQuestionIndex].minChars && questions[selectedQuestionIndex].minChars > 0
                  ? questions[selectedQuestionIndex].minChars
                  : undefined
              }
              rows={5}
              width="100%"
              onChange={(e) => {
                handleAnswering(e.target.value);
              }}
            />
          )}

          {questions[selectedQuestionIndex].answer_type.type === QuestionChoiceTypeEnum.LIST && (
            <SingleOrMultipleChoiceItemsWrapper>
              {questions[selectedQuestionIndex].answers.map((a, index) => (
                <SingleOrMultipleChoiceItem
                  key={index}
                  selected={questionsWithAnswers[selectedQuestionIndex].answered === a.id}
                  isFirst={index === 0}
                  isLast={index === questions[selectedQuestionIndex].answers.length - 1}
                  onClick={() => {
                    handleAnswering(a.id);
                  }}
                >
                  <Text color={theme.colors.palette.white} variant={'paragraph2'} text={a.answer} />
                </SingleOrMultipleChoiceItem>
              ))}
            </SingleOrMultipleChoiceItemsWrapper>
          )}

          {questions[selectedQuestionIndex].answer_type.type === QuestionChoiceTypeEnum.DROPDOWN && (
            <SelectDropdown
              isSearchable={true}
              placeholder={t('questionaire_2')}
              options={getDropdownOptions(questionsWithAnswers[selectedQuestionIndex].answers)}
              value={questionsWithAnswers[selectedQuestionIndex].answered as string}
              width={'100%'}
              onChange={(value) => {
                handleAnswering(value);
              }}
            />
          )}

          <NavigationWrapper>
            <Button disabled={selectedQuestionIndex === 0} variant="secondarySmall" text={'Nazad'} minWidth={'150px'} onClick={goBack} />
            <Button
              disabled={!questionsWithAnswers[selectedQuestionIndex].answered}
              variant="secondarySmall"
              text={selectedQuestionIndex === questions.length - 1 ? t('btn_submit_questionnaire') : t('btn_forward')}
              minWidth={'150px'}
              onClick={goForward}
            />
          </NavigationWrapper>
        </>
      )}

      {submitted && !isAuthenticated && questions.length > 0 && (
        <>
          <Text variant={'paragraph1'} width={'100%'} text={t('questionaire_3')} margin={'0 0 32px 0'} textAlign="center" />

          <Flex
            padding={isMobile ? '8px' : '16px'}
            backgroundColor={theme.colors.background.secondary.BG4}
            borderRadius="16px"
            width="100%"
          >
            {showLogin ? (
              <LoginForm
                outputAnswers={outputAnswers}
                redirectRoute={RoutesEnum.SUGGESTED_THERAPISTS}
                closeModal={() => {}}
                showResetBtn={false}
                toggleReset={() => {}}
                showRegisrationBtn={true}
                toggleRegistration={() => setShowLogin(false)}
              />
            ) : (
              <RegisterForm
                outputAnswers={outputAnswers}
                role={role}
                showLogin={true}
                redirectRoute={RoutesEnum.SUGGESTED_THERAPISTS}
                toggleLogin={() => setShowLogin(true)}
              />
            )}
          </Flex>
        </>
      )}
    </QuestionnaireWrapper>
  );
};

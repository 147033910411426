import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { parseError } from 'src/utils/error-parser';
import { ToastNotifications } from 'src/utils/toast-notifications';
import { Package } from 'src/types/Package';
import { packagesAPI } from 'src/api/package/ApiRequests';

export interface PackageState {
  loading: boolean;
  packages: Package[];
  selectedPackage: Package;
}

const initialState: PackageState = {
  loading: false,
  packages: [],
  selectedPackage: localStorage.getItem('selectedPackage') ? JSON.parse(localStorage.getItem('selectedPackage')) : undefined,
};

export const getPackages = createAsyncThunk('getPackages', async (_, thunkAPI) => {
  try {
    return await packagesAPI.getPackages();
  } catch (error) {
    const err = parseError(error);
    ToastNotifications.error(err, 'getPackages');
    return thunkAPI.rejectWithValue(error);
  }
});

export const packagesSlice = createSlice({
  name: 'packagesSlice',
  initialState,
  reducers: {
    setSelectedPackage: (state, action) => {
      state.selectedPackage = action.payload;

      if (action.payload) {
        localStorage.setItem('selectedPackage', JSON.stringify(action.payload));
      } else {
        localStorage.removeItem('selectedPackage');
      }
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getPackages.pending, (state) => {
        state.loading = true;
      })
      .addCase(getPackages.fulfilled, (state, action: any) => {
        state.loading = false;
        state.packages = action.payload.data;
      })
      .addCase(getPackages.rejected, (state) => {
        state.loading = false;
        state.packages = [];
      });
    // .addCase(pay.fulfilled, (state, action: any) => {
    //   storageService.clearAppointment();
    // });
  },
});

export const { setSelectedPackage } = packagesSlice.actions;

export default packagesSlice.reducer;

import React, { useState, useEffect, createContext, ReactNode } from 'react';
import { io } from 'socket.io-client';
import { config } from 'src/config/config';
import { storageService } from 'src/utils/storage';

export const initializeSocket = (token: string) => {
  return io(`${config.WEBSOCKET_URL}`, {
    auth: {
      token,
    },
  });
};

export const SocketContext = createContext({
  socket: null, // Initial value for socket
  reloadSocket: () => {}, // No-op function for reloadSocket
});

interface SocketProps {
  children: ReactNode;
}

export const SocketProvider = ({ children }: SocketProps) => {
  const [socket, setSocket] = useState(() => initializeSocket(storageService.getToken()));

  const reloadSocket = () => {
    const token = storageService.getToken();
    const newSocket = initializeSocket(token);

    socket.disconnect(); // Disconnect the old socket
    setSocket(newSocket);
  };

  useEffect(() => {
    return () => {
      socket.disconnect(); // Clean up the socket when the provider unmounts
    };
  }, [socket]);

  return <SocketContext.Provider value={{ socket, reloadSocket }}>{children}</SocketContext.Provider>;
};

import React, { useContext, useState, useRef, useEffect, useMemo } from 'react';
import MasterCardIcon from 'src/assets/images/monri/mastercard.png';
import MaestroIcon from 'src/assets/images/monri/maestro.png';
import VisaIcon from 'src/assets/images/monri/visa.jpg';
import MonriIcon from 'src/assets/images/monri/monri.png';
import BankTransfer from 'src/assets/images/bank-transfer.svg';
import { Card, Flex, Text, Grid, Button } from 'src/components/common';
import { InlineTextWrapper, Spinner } from 'src/components/shared';
import { IsMobileContext } from 'src/context/IsMobileContext';
import { useTranslation } from 'react-i18next';
import { useTheme } from 'styled-components';
import { MonriPaymentForm } from './MonriPaymentForm';
import { CheckBox, ToggleButtonLabel, ToggleButtonWrapper } from 'src/components/common/toggle-button/styles';
import { config } from 'src/config/config';
import { goToUrl } from 'src/utils/go-to-url';
import { monriAPI } from 'src/api/monri/ApiRequests';
import { IClientSecretResponseDTO } from 'src/api/monri/ResponseDtos';
import { userAPI } from 'src/api/user/ApiRequests';
import { ToastNotifications } from 'src/utils/toast-notifications';
import { parseError } from 'src/utils/error-parser';
import { Package } from 'src/types/Package';

export interface BookSessionCardStepProps {
  loading: boolean;
  amount: number;
  orderNumber: string;
  previewPrice: number;
  currency: string;
  selectedPackage?: Package;
  backClicked: () => void;
  paymentSuccessful: (orderNumber: string, panToken: string) => void;
}

export const BookSessionPaymentStep = ({
  loading,
  amount,
  orderNumber,
  previewPrice,
  currency,
  selectedPackage,
  backClicked,
  paymentSuccessful,
}: BookSessionCardStepProps) => {
  const { isMobile } = useContext(IsMobileContext);
  const { t } = useTranslation();
  const theme = useTheme();

  const [activeStep, setActiveStep] = useState(0);
  const [clientSecret, setClientSecret] = useState<IClientSecretResponseDTO>();
  const [acceptedTerms, setAcceptedTerms] = useState(false);
  const [acceptedPayment, setAcceptedPayment] = useState(false);

  const formRef = useRef(null);

  useEffect(() => {
    const getClientSecret = async (amount: number) => {
      const response = await monriAPI.getClientSecret({ amount, orderNumber });
      setClientSecret(response.data);
    };
    getClientSecret(amount);
  }, [amount]);

  const isPayEnabled = useMemo(() => {
    return acceptedTerms && clientSecret;
  }, [acceptedTerms, clientSecret]);

  const shouldAcceptedPayment = useMemo(() => {
    if (selectedPackage?.sessions > 1) {
      return acceptedPayment;
    } else {
      return true;
    }
  }, [selectedPackage, acceptedPayment]);

  const payClicked = () => {
    if (formRef.current) {
      formRef.current.requestSubmit();
    }
  };

  const sendBankInstructions = async () => {
    try {
      const response = await userAPI.sendBankInstructions();
      if (response.success) {
        ToastNotifications.success(response.data, 'sendBankInstructions');
      } else {
        ToastNotifications.error(response.error, 'sendBankInstructions');
      }
    } catch (error) {
      ToastNotifications.error(parseError(error), 'sendBankInstructions');
    }
  };

  const PayWithCreditCard = () => {
    return (
      <Flex
        width={'100%'}
        height={isMobile ? 'auto' : '100%'}
        flexDirection={'column'}
        gap={'24px'}
        justifyContent={'center'}
        alignItems={'center'}
      >
        {loading ? (
          <Spinner size={40} color={theme.colors.palette.purple} inline />
        ) : (
          <Flex width={isMobile ? '100%' : '50%'} flexDirection={'column'} justifyContent={'flex-start'} alignItems={'flex-start'}>
            <MonriPaymentForm formRef={formRef} clientSecret={clientSecret} paymentSuccessful={paymentSuccessful} />
            <ToggleButtonWrapper style={{ marginBottom: 24, marginTop: 16 }}>
              <CheckBox id="acceptedTerms" type="checkbox" checked={acceptedTerms} onChange={() => setAcceptedTerms(!acceptedTerms)} />
              <ToggleButtonLabel htmlFor="acceptedTerms" />
              <InlineTextWrapper>
                <Text margin="0 4px 0 0" variant={'paragraph1'} text={t('book_therapist_2')} />
                <Text
                  margin="0 4px 0 0"
                  variant={'paragraph1'}
                  text={t('book_therapist_3')}
                  color={theme.colors.palette.purple}
                  style={{ textDecoration: 'underline' }}
                  onClick={() => goToUrl(config.PAYMENT_POLICY_URL, '_blank')}
                />
              </InlineTextWrapper>
            </ToggleButtonWrapper>
            {selectedPackage?.sessions > 1 && (
              <ToggleButtonWrapper>
                <CheckBox
                  id="acceptedPayment"
                  type="checkbox"
                  checked={acceptedPayment}
                  onChange={() => setAcceptedPayment(!acceptedPayment)}
                />
                <ToggleButtonLabel htmlFor="acceptedPayment" />
                <InlineTextWrapper margin="0 0 0 24px">
                  <Text variant={'paragraph1'} text={t('book_therapist_6')} />
                </InlineTextWrapper>
              </ToggleButtonWrapper>
            )}
          </Flex>
        )}

        <Text variant={'paragraph2'} text={t('book_therapist_5')} whiteSpace={'pre-wrap'} />

        <Grid width={isMobile ? '80%' : '30%'} gridTemplateColumns={'1fr 1fr 1fr 1fr'} gridTemplateRows={'1fr'} gap={'10px'}>
          <a href="https://www.mastercard.hr/hr-hr.html" target="_blank" rel="noreferrer">
            <img src={MasterCardIcon} alt="mastercard-icon" height={isMobile ? 30 : 40} />
          </a>
          <a href="https://brand.mastercard.com/brandcenter/more-about-our-brands.html" target="_blank" rel="noreferrer">
            <img src={MaestroIcon} alt="maestro-icon" height={isMobile ? 30 : 40} />
          </a>
          <a href="https://www.visaeurope.com" target="_blank" rel="noreferrer">
            <img src={VisaIcon} alt="visa-icon" height={isMobile ? 30 : 40} />
          </a>
          <a href="https://monri.com" target="_blank" rel="noreferrer">
            <img src={MonriIcon} alt="monri-icon" height={isMobile ? 30 : 40} />
          </a>
        </Grid>
      </Flex>
    );
  };

  const PayWithBank = () => {
    return (
      <Flex
        width={'100%'}
        height={'100%'}
        padding={isMobile ? '16px 0 0 0' : '0 10%'}
        flexDirection={'column'}
        gap={'8px'}
        justifyContent={'center'}
      >
        <Text width={'100%'} variant={'heading5'} text={`Plaćanje putem bankovnog računa`} />
        <Text variant={'paragraph1'} text={'Za plaćanje putem bankovnog računa, molimo slijedite ove korake:'} />
        <Text margin="24px 0 0 16px" variant={'paragraph1'} text={"1. Kliknite na dugme 'Upute za plaćanje' ispod."} />
        <Text margin="0 0 0 16px" variant={'paragraph1'} text={'2. Na vašu email adresu stići će detaljne upute za plaćanje.'} />
        <Text margin="0 0 0 16px" variant={'paragraph1'} text={'3. Izvršite uplatu prema dobijenim uputama.'} />
        <Text
          margin="0 0 0 16px"
          variant={'paragraph1'}
          text={
            '4. Nakon što izvršite uplatu, molimo vas da nam pošaljete potvrdu o plaćanju na našu email adresu (ista adresa sa koje ste dobili upute).'
          }
        />
        <Text
          margin="0 0 0 16px"
          variant={'paragraph1'}
          text={'5. Nakon što primimo potvrdu o plaćanju, poslat ćemo vam kod (ili kodove u slučaju da ste uplatili paket s više sesija).'}
        />
        <Text
          margin="0 0 24px 16px"
          variant={'paragraph1'}
          text={'6. Dobijeni kod(ove) možete koristiti za rezervaciju sesija s vašim željenim terapeutom.'}
        />
        <Text variant={'paragraph1'} text={'Hvala vam na povjerenju!'} />

        <Button margin="24px 0 0 0" variant="accentDefault" text={'Upute za plaćanje'} onClick={sendBankInstructions} />
      </Flex>
    );
  };

  return (
    <Card width={'100%'} height={isMobile ? 'auto' : '75vh'} borderRadius={'20px'} padding={isMobile ? '16px' : '24px'}>
      <Flex
        width={'100%'}
        height={'100%'}
        flexDirection={'column'}
        gap={'32px'}
        justifyContent={'space-between'}
        alignItems={'center'}
        overflow={'scroll'}
      >
        <Flex width={'100%'} height={'100%'} flexDirection={isMobile ? 'column' : 'row'}>
          <Flex width={isMobile ? '100%' : '20%'} flexDirection={isMobile ? 'row' : 'column'} gap={'16px'}>
            <Card
              width={'100%'}
              height={isMobile ? '130px' : 'auto'}
              padding={'20px'}
              borderRadius={'20px'}
              border={`2px solid ${activeStep == 0 ? theme.colors.palette.green : theme.colors.border.lighter}`}
            >
              <Flex
                width={'100%'}
                height={'100%'}
                flexDirection={'column'}
                gap={'16px'}
                justifyContent={'center'}
                alignItems={'center'}
                onClick={() => setActiveStep(0)}
              >
                <Text width={'100%'} textAlign={'center'} variant={'heading7'} text={`Kreditna kartica`} />
                <Grid gridTemplateColumns={'1fr 1fr 1fr'} gridTemplateRows={'1fr'} gap={'10px'}>
                  <img src={MasterCardIcon} alt="mastercard-icon" height={isMobile ? 30 : 40} />
                  <img src={MaestroIcon} alt="maestro-icon" height={isMobile ? 30 : 40} />
                  <img src={VisaIcon} alt="visa-icon" height={isMobile ? 30 : 40} />
                </Grid>
              </Flex>
            </Card>
            <Card
              width={'100%'}
              height={isMobile ? '130px' : 'auto'}
              padding={'20px'}
              borderRadius={'20px'}
              border={`2px solid ${activeStep == 1 ? theme.colors.palette.green : theme.colors.border.lighter}`}
            >
              <Flex
                width={'100%'}
                height={'100%'}
                flexDirection={'column'}
                gap={'16px'}
                justifyContent={'center'}
                alignItems={'center'}
                onClick={() => setActiveStep(1)}
              >
                <Text width={'100%'} textAlign={'center'} variant={'heading7'} text={`Uplata preko banke`} />
                <img src={BankTransfer} alt="" height={isMobile ? 30 : 40} />
              </Flex>
            </Card>
          </Flex>
          <Flex width={isMobile ? '100%' : '80%'} height={'100%'}>
            {activeStep == 0 && PayWithCreditCard()}
            {activeStep == 1 && PayWithBank()}
          </Flex>
        </Flex>

        <Flex width={'100%'} flexDirection={'row'} justifyContent={'space-between'} alignItems={'stretch'}>
          <Button variant="secondaryDefault" text={t('btn_back')} onClick={backClicked} />

          {activeStep == 0 && (
            <Button
              variant="primaryDefault"
              text={`Rezerviši i plati (${previewPrice} ${currency})`}
              isLoading={loading}
              disabled={loading || !isPayEnabled || !shouldAcceptedPayment}
              onClick={payClicked}
            />
          )}
        </Flex>
      </Flex>
    </Card>
  );
};

import React, { useContext, useState } from 'react';
import { useTheme } from 'styled-components';
import { IsMobileContext } from 'src/context/IsMobileContext';
import { Flex, Modal, Text } from 'src/components/common';
import { RootState, useAppSelector, useAppDispatch } from 'src/store';
import { toggleRegistrationModal } from 'src/store/slices/authSlice';
import { RolesEnum } from 'src/constants/RolesEnum';
import { useLocation } from 'react-router-dom';
import { RoutesEnum } from 'src/routes';
import LoginForm from 'src/components/shared/login-modal/form/login/LoginForm';
import ResetPasswordForm from 'src/components/shared/login-modal/form/reset-password/ResetPasswordForm';
import RegisterForm from 'src/components/shared/register-form/RegisterForm';
import { useTranslation } from 'react-i18next';

export const RegisterModal = () => {
  const dispatch = useAppDispatch();

  const theme = useTheme();
  const location = useLocation();

  const { showRegistrationodal } = useAppSelector((state: RootState) => state.auth);
  const { isMobile } = useContext(IsMobileContext);
  const { t } = useTranslation();

  const [showReset, setShowReset] = useState(false);
  const [showLogin, setShowLogin] = useState(false);

  const showRegisrationBtn = location.pathname.includes(RoutesEnum.THERAPIST_DETAILS.slice(0, -3));

  const toggleReset = () => {
    setShowLogin(false);
    setShowReset(!showReset);
  };

  const toggleRegistration = () => {
    setShowReset(false);
    setShowLogin(!showLogin);
  };

  const toggleLogin = () => {
    setShowReset(false);
    setShowLogin(true);
  };

  const onClose = () => {
    dispatch(toggleRegistrationModal(false));
  };

  return (
    <Modal
      isOpen={showRegistrationodal}
      onClose={onClose}
      size="medium"
      backgroundColor={theme.colors.background.secondary.BG4}
      preventOnClickOutside
    >
      <Flex flexDirection="column" alignItems={'center'} justifyContent={'center'} width={'100%'} position={'relative'}>
        <Flex
          padding={isMobile ? '10px' : '0px'}
          flexDirection="column"
          backgroundColor={theme.colors.background.secondary.BG4}
          borderRadius="16px"
          width="100%"
        >
          {showReset && (
            <>
              <Text variant={'heading5'} text={t('login_1')} margin="0 auto 48px auto" />
              <ResetPasswordForm toggleReset={toggleReset} />
            </>
          )}

          {showLogin && (
            <>
              <Text variant={'heading5'} text={t('login_3')} margin="0 auto 48px auto" />
              <LoginForm
                outputAnswers={[]}
                redirectRoute={null}
                closeModal={onClose}
                showResetBtn={true}
                toggleReset={toggleReset}
                showRegisrationBtn={showRegisrationBtn}
                toggleRegistration={toggleRegistration}
              />
            </>
          )}

          {!showReset && !showLogin && (
            <>
              <Text variant={'heading5'} text={t('login_2')} margin="0 auto 48px auto" />
              <RegisterForm
                role={RolesEnum.CLIENT_INDIVIDUAL}
                outputAnswers={[]}
                showLogin={true}
                redirectRoute={null}
                toggleLogin={toggleLogin}
              />
            </>
          )}
        </Flex>
      </Flex>
    </Modal>
  );
};

import React from 'react';

import { Flex, Text } from 'src/components/common';

export const Forbidden = () => {
  return (
    <Flex flexDirection="column" alignItems={'center'} justifyContent={'center'} flex={'1'} gap={'16px'}>
      <Text variant={'heading1'} text={'Greška - 403'} />
      <Text variant={'heading7'} text={'Nemate dozvolu za pristup ovoj stranici.'} />
    </Flex>
  );
};

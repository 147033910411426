import { createSlice } from '@reduxjs/toolkit';

export interface GlobalState {
  language: string;
  showRecommendFriendModal: boolean;
  showEditProfileModal: boolean;
  showDeleteProfileAlert: boolean;
  activeTab: number;
  burgerMenuItems: any;
  dashboardNavItems: any;
  showTutorialModal: boolean;
}

const initialState: GlobalState = {
  language: localStorage.getItem('language') ? JSON.parse(localStorage.getItem('language')) : 'ba',
  showRecommendFriendModal: false,
  showEditProfileModal: false,
  showDeleteProfileAlert: false,
  activeTab: localStorage.getItem('activeTab') ? Number(JSON.parse(localStorage.getItem('activeTab'))) : 1,
  burgerMenuItems: undefined,
  dashboardNavItems: undefined,
  showTutorialModal: false,
};

export const globalSlice = createSlice({
  name: 'globalSlice',
  initialState,
  reducers: {
    setLanguage: (state, action) => {
      state.language = action.payload;
      localStorage.setItem('language', JSON.stringify(action.payload));
    },
    setShowRecommendFriendModal: (state, action) => {
      state.showRecommendFriendModal = action.payload;
    },
    setShowEditProfileModal: (state, action) => {
      state.showEditProfileModal = action.payload;
    },
    setShowDeleteProfileAlert: (state, action) => {
      state.showDeleteProfileAlert = action.payload;
    },
    setActiveTab: (state, action) => {
      state.activeTab = action.payload;

      if (action.payload) {
        localStorage.setItem('activeTab', JSON.stringify(action.payload));
      } else {
        localStorage.removeItem('activeTab');
      }
    },
    setBurgerMenuItems: (state, action) => {
      state.burgerMenuItems = action.payload;
    },
    setDashboardNavItems: (state, action) => {
      state.dashboardNavItems = action.payload;
    },
    setShowTutorialModal: (state, action) => {
      state.showTutorialModal = action.payload;
    },
  },
  extraReducers() {},
});

export const {
  setLanguage,
  setShowRecommendFriendModal,
  setShowEditProfileModal,
  setShowDeleteProfileAlert,
  setActiveTab,
  setBurgerMenuItems,
  setDashboardNavItems,
  setShowTutorialModal,
} = globalSlice.actions;

export default globalSlice.reducer;

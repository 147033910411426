import React, { useCallback, useEffect, useRef, useState } from 'react';
import Select, { SingleValue, MultiValue, MenuListProps } from 'react-select';
import { FilterOptionOption } from 'react-select/dist/declarations/src/filters';
import { useTheme } from 'styled-components';

import { Text } from 'src/components/common';
import { SelectDropdownProps, SelectDropdownOption } from './SelectDropdown.types';
import { SelectWrapper, ErrorMessageWrapper } from './SelectDropdown.styles';
import { CustomMenu, CustomMenuList, CustomMultiValueContainer, CustomOption, CustomValueContainer } from './components';

export const SelectDropdown = (props: SelectDropdownProps) => {
  const {
    options,
    label = '',
    disabled = false,
    isSearchable = false,
    isMultiple = false,
    hasSelectAll = false,
    maxSelectable = undefined,
    margin = '0px',
    value = '',
    minWidth = undefined,
    errorMessage = '',
    placeholder = 'Select...',
    width = '100%',
    variant = 'default',
    onChange,
    ...rest
  } = props;
  const theme = useTheme();
  const selectWrapperRef = useRef(null);

  const [internalValue, setInternalValue] = useState<SingleValue<SelectDropdownOption> | MultiValue<SelectDropdownOption> | undefined>(
    undefined
  );
  const [isFocused, setIsFocused] = useState(false);
  const [optionsAvailable, setOptionsAvailable] = useState(options);

  const CustomComponents = {
    MultiValueContainer: CustomMultiValueContainer,
    Option: CustomOption,
    Menu: CustomMenu,
    MenuList: (props: MenuListProps<SelectDropdownOption>) => CustomMenuList(props),
    ValueContainer: CustomValueContainer,
  };

  const customFilter = (item: FilterOptionOption<SelectDropdownOption>, searchString: string) => {
    const { label, value, searchLabel } = item.data;
    const searchData = searchString.toLowerCase();
    const labelContainsSearch = typeof label === 'string' && label.toLowerCase().includes(searchData);
    const valueContainsSearch = value.toString().toLowerCase().includes(searchData);
    const searchLabelContainsSearch = searchLabel?.toLowerCase().includes(searchData) || false;
    return labelContainsSearch || valueContainsSearch || searchLabelContainsSearch;
  };

  const getSelectedOption = useCallback(() => {
    if (optionsAvailable && optionsAvailable.filter((o) => o.value === value).length >= 1) {
      const selected = optionsAvailable.filter((o) => o.value === value);
      return isMultiple ? selected : selected[0];
    }
    return undefined;
  }, [optionsAvailable, value, isMultiple]);

  const handleChange = (option: SingleValue<SelectDropdownOption> | MultiValue<SelectDropdownOption> | undefined) => {
    if (isMultiple && maxSelectable && typeof maxSelectable === 'number' && Array.isArray(option)) {
      if (option.length === maxSelectable) {
        const restrictToAlreadySelectedOptions = options
          .map((o) => option.find((selectedOption) => selectedOption.value === o.value))
          .filter(Boolean);

        setOptionsAvailable(restrictToAlreadySelectedOptions);
      } else {
        setOptionsAvailable(options);
      }
    }

    setInternalValue(option);
    !isMultiple && setIsFocused(false);

    if (typeof onChange === 'function') {
      onChange(Array.isArray(option) ? option.map((item) => item.value) : (option as SingleValue<SelectDropdownOption>)?.value);
    }
  };

  // for closing menu and focus out
  const onDomClick = (e: MouseEvent) => {
    if (selectWrapperRef.current) {
      const menu = (selectWrapperRef.current as HTMLElement).querySelector('.select__menu');
      if (!(selectWrapperRef.current as HTMLElement).contains(e.target as Node) || !menu || !menu.contains(e.target as Node)) {
        setIsFocused(false);
      }
    }
  };

  useEffect(() => {
    if (getSelectedOption() && getSelectedOption() !== internalValue) {
      setInternalValue(getSelectedOption());
    }
  }, [value, optionsAvailable, getSelectedOption, internalValue]);

  useEffect(() => {
    document.addEventListener('mousedown', onDomClick);
    return () => {
      document.removeEventListener('mousedown', onDomClick);
    };
  }, []);

  return (
    <SelectWrapper ref={selectWrapperRef} margin={margin} width={width} variant={variant} minWidth={minWidth} {...rest}>
      {label && <Text text={label} variant="paragraph2" margin="0 0 4px 0" />}

      <Select
        className={isMultiple ? 'basic-multi-select' : 'basic-single'}
        classNamePrefix="select"
        placeholder={placeholder}
        value={internalValue}
        isDisabled={disabled}
        backspaceRemovesValue={false}
        isLoading={false}
        isClearable={false}
        isRtl={false}
        hideSelectedOptions={false}
        isSearchable={false}
        isMulti={isMultiple}
        filterOption={customFilter}
        options={optionsAvailable}
        onChange={handleChange}
        components={CustomComponents}
        closeMenuOnSelect={!isMultiple}
        {...{
          includesSearch: isSearchable,
          hasSelectAll: hasSelectAll,
          maxSelectable: maxSelectable,
          menuIsOpen: isFocused || undefined,
          isFocused: isFocused || undefined,
          onMenuInputFocus: () => setIsFocused(true),
        }}
      />

      {errorMessage && (
        <ErrorMessageWrapper>
          <Text text={errorMessage} variant="paragraph3" color={theme.colors.palette.red} />
        </ErrorMessageWrapper>
      )}

      {isMultiple && maxSelectable && (
        <ErrorMessageWrapper>
          <Text
            text={`Napomena: Možete označiti maksimalno ${maxSelectable} odgovora`}
            margin={'4px 0 0 4px'}
            variant="paragraph3"
            color={theme.colors.text.secondary}
          />
        </ErrorMessageWrapper>
      )}
    </SelectWrapper>
  );
};

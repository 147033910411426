import React, { useCallback, useEffect, useContext } from 'react';
import { useNavigate, generatePath } from 'react-router-dom';
import styled from 'styled-components';

import ProfilePhotoPlaceholder from 'src/assets/images/profile-avatar.png';
import { IsMobileContext } from 'src/context/IsMobileContext';
import { Button, Flex, Text } from 'src/components/common';
import { RoutesEnum } from 'src/routes';
import { RootState, useAppSelector, useAppDispatch } from 'src/store';
import { getSuggestedTherapists } from 'src/store/slices/userSlice';
import { User } from 'src/types/User';
import { DotsSpinner } from 'src/components/shared';
import { useTranslation } from 'react-i18next';

const TherapistCard = styled.div`
  display: flex;
  flex-direction: column;
  width: ${(props) => props.width || 'auto'};
  align-items: flex-start;
  justify-content: flex-start;
  border-radius: 16px;
  position: relative;
  overflow: hidden;
  transition: all 0.2s ease;
  z-index: 1;

  &:hover {
    transform: scale(1.1);
    transition: all 0.2s ease;
    z-index: 10;

    .therapist-details-button,
    .card-backdrop {
      display: block;
    }
  }
`;

const Backdrop = styled.div`
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 1);
`;

const TherapistDetailsButtonContainer = styled.div`
  display: none;
  position: absolute;
  width: 50%;
  top: 30%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 999;
  opacity: 1;

  * {
    opacity: 1 !important;
  }
`;

const ProfessionBadge = styled.div`
  display: flex;
  width: max-content;
  max-width: 100%;
  background-color: ${(props) => props.theme.colors.palette.green};
  align-items: center;
  justify-content: center;
  border-radius: 32px;
  padding: 12px 24px;
`;

export const SuggestedTherapists = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const { t } = useTranslation();
  const { isMobile } = useContext(IsMobileContext);

  const { user } = useAppSelector((state: RootState) => state.auth);
  const { suggestedTherapists, suggestedTherapistsLoading } = useAppSelector((state: RootState) => state.user);

  useEffect(() => {
    dispatch(getSuggestedTherapists());
  }, []);

  const goToTherapistDetails = useCallback(
    (id: string | number) => {
      if (!id) {
        return;
      }
      window.location.href = generatePath(RoutesEnum.THERAPIST_DETAILS, { id: id.toString() });
    },
    [navigate]
  );

  const calculatePrice = (therapist: User) => {
    const bam = Number(therapist?.price ?? 0) + Number(process.env.REACT_APP_PRICE_FEE ?? 0);
    const roundBAM = Number.isInteger(bam) ? bam : bam.toFixed(2);
    const eur = Number(bam) / 1.95;
    const roundEUR = Number.isInteger(eur) ? eur : eur.toFixed(2);
    return user?.country != 'Bosna i Hercegovina' ? `${roundEUR} EUR` : `${roundBAM} BAM`;
  };

  if (suggestedTherapistsLoading) {
    return <DotsSpinner inline style={{ margin: 'auto' }} />;
  }

  return (
    <Flex
      flexDirection="column"
      alignItems={'center'}
      justifyContent={'center'}
      width={'100%'}
      position={'relative'}
      padding={isMobile ? '30px' : '50px 100px'}
    >
      <Text
        variant={'heading4'}
        text={`${t('matches_1')} ${suggestedTherapists.length} ${t('matches_2')}`}
        margin={'0 0 32px 0'}
        textAlign={'center'}
        whiteSpace={'pre-wrap'}
        width={isMobile ? '100%' : '85%'}
      />

      <Text variant={'paragraph1'} text={t('matches_3')} margin={'0 0 64px 0'} textAlign={'center'} width={isMobile ? '100%' : '75%'} />

      <Flex width={'100%'} gap={'20px'} justifyContent="center" flexWrap="wrap">
        {suggestedTherapists.map((item, index) => (
          <TherapistCard key={index} width={isMobile ? '100%' : `${100 / (suggestedTherapists.length + 2)}%`}>
            <Backdrop className="card-backdrop" />

            <TherapistDetailsButtonContainer className="therapist-details-button">
              <Button variant={'secondarySmall'} text={t('btn_details')} width="100%" onClick={() => goToTherapistDetails(item.id)} />
            </TherapistDetailsButtonContainer>

            <Flex width={'100%'} flexDirection={'column'} justifyContent={'center'} alignItems={'center'}>
              <img src={item.photo ?? ProfilePhotoPlaceholder} alt="Photo" width={'100%'} height={'300px'} style={{ objectFit: 'cover' }} />

              {isMobile && (
                <Text variant={'paragraph3'} width={'100%'} text={t('btn_more_details')} margin={'4px 0 0 0'} textAlign={'center'} />
              )}

              <Flex flexDirection={'column'} width={'100%'} padding={'24px 32px'} justifyContent={'center'} alignItems={'center'}>
                <Text variant={'heading5'} width={'100%'} text={item.username} margin={'0 0 16px 0'} textAlign={'center'} />
                <Text
                  variant={'paragraph3'}
                  text={`${t('matches_4')} ${calculatePrice(item)} ${t('matches_5')}`}
                  whiteSpace="pre-wrap"
                  textAlign="center"
                  margin={'16px 0 16px 0'}
                />
              </Flex>
              {item.freeSession && (
                <ProfessionBadge>
                  <Text variant={'paragraph3'} text={t('matches_6')} />
                </ProfessionBadge>
              )}
            </Flex>
          </TherapistCard>
        ))}
      </Flex>
    </Flex>
  );
};

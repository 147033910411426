import { httpService } from 'src/config/http';
import { ApiResponse } from 'src/types/ApiResponse';
import { Package } from 'src/types/Package';
import { ISubscribeRequestDTO } from './RequestDtos';

export default class PackagesApi {
  public getPackages() {
    return httpService.get<ApiResponse<Package[]>>('/package');
  }

  public subscribe(data: ISubscribeRequestDTO) {
    return httpService.post<ApiResponse<string>>('/package/subscribe', data);
  }

  public unsubscribe() {
    return httpService.post<ApiResponse<string>>('/package/unsubscribe');
  }
}

export const packagesAPI = new PackagesApi();

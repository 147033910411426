import moment from 'moment';

export const getAppointDate = (timestamp: number) => {
  const parsedDate = new Date(timestamp);
  const date = parsedDate.toLocaleDateString('en-GB');
  const time = parsedDate.toLocaleTimeString(navigator.language, { hour: '2-digit', minute: '2-digit' });
  return `${date} u ${time}`;
};

export const getAppointTime = (timestamp: number) => {
  const parsedDate = new Date(timestamp);
  const time = parsedDate.toLocaleTimeString(navigator.language, { hour: '2-digit', minute: '2-digit' });
  return `${time}`;
};

export const getTimeFromDate = (date: Date, format = 'HH:mm') => {
  return moment(date).format(format);
};

export const getDateFromTimestamp = (timestamp: number) => {
  return new Date(timestamp);
};

export const checkIfDateAndTimestampAreEqualByHourDayMonthYear = (date: Date, timestamp: number) => {
  const date1 = date;
  date1.setSeconds(0);
  const date2 = new Date(timestamp);
  date2.setSeconds(0);
  return (
    date1.getHours() === date2.getHours() &&
    date1.getDate() === date2.getDate() &&
    date1.getMonth() === date2.getMonth() &&
    date1.getFullYear() === date2.getFullYear()
  );
};

export const formatDate = (createdAt: string) => {
  return moment(createdAt).format('HH:mm, D-MM-yyyy');
};

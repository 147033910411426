import { configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/dist/query';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';

import authReducer from './slices/authSlice';
import userReducer from './slices/userSlice';
import questionsReducer from './slices/questionsSlice';
import subscriptionsReducer from './slices/subscriptionsSlice';
import feedbackReducer from './slices/feedbackSlice';
import globalReducer from './slices/globalSlice';
import sessionReducer from './slices/sessionSlice';
import packagesReducer from './slices/packagesSlice'

const reducer = {
  auth: authReducer,
  user: userReducer,
  questions: questionsReducer,
  subscriptions: subscriptionsReducer,
  feedback: feedbackReducer,
  global: globalReducer,
  session: sessionReducer,
  packages: packagesReducer
};

export const store = configureStore({
  reducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({ serializableCheck: false }),
});

setupListeners(store.dispatch);

// Infering `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export const useAppDispatch: () => AppDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

import React, { useEffect, useContext, useState } from 'react';
import { Button, Flex } from 'src/components/common';
import { BookTherapistModal } from 'src/pages/therapist-details/modals/BookTherapistModal';
import { FeedbackModal } from 'src/components/shared';
import { useDisclosure } from 'src/hooks/useDisclosure';
import { IsMobileContext } from 'src/context/IsMobileContext';
import { RootState, useAppSelector, useAppDispatch } from 'src/store';
import { toggleFeedbackModal } from 'src/store/slices/feedbackSlice';
import { maxHorizontalPadding } from 'src/constants/common';
import { userAPI } from 'src/api/user/ApiRequests';
import { User } from 'src/types/User';
import { useTranslation } from 'react-i18next';

export const Session = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { isMobile } = useContext(IsMobileContext);

  const { user } = useAppSelector((state: RootState) => state.auth);
  const { session } = useAppSelector((state: RootState) => state.session);
  const { showFeedbackModal } = useAppSelector((state: RootState) => state.feedback);
  const { isOpen, close, open } = useDisclosure(false);
  const [roomUrl, setRoomUrl] = useState(null);
  const [therapistDetails, setTherapistDetails] = useState<User>(null);

  useEffect(() => {
    if (!user && !session) return;

    setRoomUrl(session?.meetingUrl);
    loadTherapistDetails();
  }, [user, session]);

  const loadTherapistDetails = async () => {
    if (!session?.therapist || session?.therapist?.id != user?.id) return;

    const response = await userAPI.getUserDetails(session?.therapist?.id?.toString());
    setTherapistDetails(response?.data);
  };

  const openFeedbackModal = () => {
    dispatch(toggleFeedbackModal(true));
  };

  return (
    <Flex
      flexDirection="column"
      alignItems={'center'}
      justifyContent={'center'}
      height={'100%'}
      width={'100%'}
      gap={'20px'}
      padding={isMobile ? '0px' : `50px ${maxHorizontalPadding}`}
    >
      {roomUrl != null && (
        <Flex flexDirection="column" width="100%" height="100%" gap="16px">
          <iframe
            src={roomUrl}
            width={'100%'}
            allow="camera; microphone; display-capture; clipboard-write; allowFullScreen"
            frameBorder="0"
            allowFullScreen={true}
            style={{ position: 'relative', height: isMobile ? '83vh' : '75vh' }}
          />

          <Flex width="100%" alignItems="center" gap="16px" justifyContent={isMobile ? 'center' : 'flex-start'}>
            <Button text={t('btn_rate_sesion')} minWidth={'150px'} onClick={openFeedbackModal} />
            {therapistDetails != null && <Button text={t('btn_book_new_session')} minWidth={'150px'} onClick={open} />}
          </Flex>
        </Flex>
      )}

      {showFeedbackModal && <FeedbackModal session={session} />}

      {therapistDetails != null && (
        <BookTherapistModal
          isOpen={isOpen}
          onClose={close}
          therapist={therapistDetails}
          clientId={session?.client?.id}
          redirectRoute={null}
          buttonLabel={t('btn_confirm_timeslot')}
        />
      )}
    </Flex>
  );
};
